import { useSyncedRef } from '@react-hookz/web'
import type { CompanyId } from 'api/types/companies'
import { createCancellablePromise } from 'kitchen/utils/async'
import { useEffect } from 'react'
import { useSendAnalyticsEvent } from '../queries'
import type { UIViewData, AnalyticsEventContext } from '../types'

export interface TrackUIViewOptions extends Omit<AnalyticsEventContext, 'userId'> {
  data: UIViewData
  companyId: CompanyId | undefined
  key?: React.Key
}

export const useTrackUIView = ({
  data,
  companyId,
  subjectId,
  key,
}: TrackUIViewOptions) => {
  const { mutateAsync: sendAnalyticsEvent } = useSendAnalyticsEvent()
  const dataRef = useSyncedRef(data)

  useEffect(() => {
    const promise = createCancellablePromise(() =>
      sendAnalyticsEvent({
        event: 'UI_VIEW',
        data: dataRef.current,
        companyId,
        subjectId,
      })
    )

    return () => {
      promise.abort('Cancelled')
    }
  }, [sendAnalyticsEvent, dataRef, companyId, subjectId, key])
}
