import { FullStory } from '@fullstory/browser'
import * as Sentry from '@sentry/react'
import { useCompanies } from 'api/hooks/companies'
import { useUserSettings } from 'api/hooks/user-settings'
import { useCurrentUser } from 'api/hooks/users'
import type { Company } from 'api/types/companies'
import type { AuthUser } from 'api/types/users'
import { SESSION_ID } from 'domains/analytics/constants'
import { rudderAnalytics } from 'domains/analytics/rudderstack'
import { useAuthChannel } from 'domains/auth/hooks'
import { getPracticeCompany } from 'kitchen/utils/companies'
import { formatName, assert } from 'kitchen/utils/helpers'
import { useContext, useEffect, useMemo } from 'react'
import { useIntercom } from 'react-use-intercom'
import { FullstoryContext } from '@app/fullstory'
import { useLogout } from '../..//hooks/use-logout'
import { ErrorStatus } from '../error-status'
import { LoadingStatus } from '../loading-status'

function getCustomerType(practice: Company | undefined, activeCompanies: Company[]) {
  if (activeCompanies.length === 0) {
    return 'None'
  }
  return practice === undefined ? 'Business' : 'Practice'
}

interface UpdateCurrentUserProps {
  user: AuthUser
}

function UpdateCurrentUser({ user }: UpdateCurrentUserProps) {
  const { update: updateIntercom } = useIntercom()
  const { isFullstoryInitialized } = useContext(FullstoryContext)
  const companies = useCompanies({ status: ['ACTIVE', 'DRAFT'] }, { suspense: true })

  assert(companies.data)
  const activeCompanies = useMemo(
    () => companies.data.filter((company) => company.status === 'ACTIVE'),
    [companies.data]
  )

  useEffect(() => {
    if (isFullstoryInitialized) {
      FullStory('setIdentity', {
        uid: user.id,
        properties: {
          sessionId: SESSION_ID,
        },
      })
    }
  }, [isFullstoryInitialized, user])

  useEffect(() => {
    Sentry.setUser({
      id: user.id,
    })
    const practice = getPracticeCompany(activeCompanies)

    // TODO: Return and don't update intercom after successful release
    if (user.intercomToken === null) {
      Sentry.captureException(new Error('Missing intercom token'), {
        tags: { type: 'intercom' },
      })
    }

    updateIntercom({
      customAttributes: {
        session_id: SESSION_ID,
        customer_type: getCustomerType(practice, activeCompanies),
        practice_id: practice?.id,
        practice_segment: practice?.segment,
      },
      userId: user.id,
      email: user.email,
      userHash: user.intercomToken || undefined,
      name: formatName(user.firstName, user.lastName),
    })

    rudderAnalytics.identify(user.id, {
      customerType: getCustomerType(practice, activeCompanies),
      practiceId: practice?.id,
      sessionId: SESSION_ID,
      event_id: user.id, // used for event deduplication
    })

    return () => {
      Sentry.setUser(null)
      updateIntercom({
        userId: undefined,
        email: undefined,
        name: undefined,
        customAttributes: undefined,
        userHash: undefined,
      })
    }
  }, [updateIntercom, user, activeCompanies])

  return null
}

export interface AuthorizedProps {
  children?: React.ReactNode
}

export const Authorized = ({ children }: AuthorizedProps) => {
  const authChannel = useAuthChannel()
  const currentUser = useCurrentUser()
  const userSettings = useUserSettings()
  const logout = useLogout()

  useEffect(() => {
    const unsubscribe = authChannel.subscribe((message) => {
      if (message.type === 'logout') {
        logout({ broadcastToAllTabs: false })
      }
    })

    return () => unsubscribe()
  }, [authChannel, logout])

  if (currentUser.isLoading || userSettings.isLoading) {
    return <LoadingStatus layout="flow-page" />
  }

  if (currentUser.isError || userSettings.isError) {
    return <ErrorStatus.Root layout="flow-page" />
  }

  return (
    <>
      {children}
      <UpdateCurrentUser user={currentUser.data} />
    </>
  )
}
