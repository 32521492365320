import { useRef, useState } from 'react'
import { Image, ZStack } from '../../primitives'
import { VideoButton } from '../video-button'

export type VideoState = 'loading' | 'ready' | 'playing' | 'paused' | 'ended'

interface VideoProps extends React.VideoHTMLAttributes<HTMLVideoElement> {
  children: React.ReactNode
}

export function Video({ children, ...rest }: VideoProps) {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [state, setState] = useState<VideoState>('loading')

  return (
    <ZStack css={{ placeItems: 'center' }}>
      <Image
        as="video"
        ref={videoRef}
        onLoadedData={(event) => {
          if (event.currentTarget.readyState >= HTMLMediaElement.HAVE_ENOUGH_DATA) {
            setState('ready')
          }
        }}
        onPlaying={() => setState('playing')}
        onPause={() => setState('paused')}
        onEnded={(event) => {
          setState('ended')
          event.currentTarget.currentTime = 0
        }}
        {...rest}
      >
        {children}
      </Image>
      <VideoButton
        size={96}
        onClick={() => {
          const video = videoRef.current

          if (video === null) {
            return
          }

          video[video.paused ? 'play' : 'pause']()
        }}
        playing={state === 'playing'}
        loading={state === 'loading'}
      />
    </ZStack>
  )
}
