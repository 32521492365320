import { AnimatedSuccessIcon, Surface, Status } from 'salad/components'
import { Confetti } from '../confetti'

interface GiftPaymentsFreeTrialConfirmationProps {
  onDone: () => void
}

export function GiftPaymentsFreeTrialConfirmation({
  onDone,
}: GiftPaymentsFreeTrialConfirmationProps) {
  return (
    <Surface p={32} style={{ maxWidth: 448, marginInline: 'auto' }}>
      <Status.Root variant="dialog">
        <Status.Icon>
          <Confetti variant="snow" size={64}>
            <AnimatedSuccessIcon size={64} />
          </Confetti>
        </Status.Icon>
        <Status.Title>Hooray! Your gift is on the way.</Status.Title>
        <Status.Action variant="common" onClick={() => onDone()}>
          Sounds good
        </Status.Action>
      </Status.Root>
    </Surface>
  )
}
