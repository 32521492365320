import { ErrorCode, type ApiRequest } from 'kitchen/types'
import { CardPaymentError, ExtendedResponseError } from 'kitchen/utils/error'
import type { CurrencyCode } from '../money/types'
import type {
  PaidPayment,
  PendingPayment,
  PaymentsListPayload,
  PaymentsListResponse,
  PaymentProgressPayload,
  PaymentProgressResponse,
  PaymentFundingAccount,
  PaymentFundingAccountPayload,
  CompaniesPaymentStatsResponse,
  PaymentStats,
  PaymentStatsPayload,
  CreateCardPaymentPayload,
  CreateGetPaidCardPaymentPayload,
  CardPaymentPayload,
  CreatePlaidPaymentPayload,
  CreatePlaidPaymentResponse,
  CardPaymentResponse,
  CreateGetPaidPlaidPaymentPayload,
  CreateWalletPaymentPayload,
} from './types'

export const getPaidPaymentsList: ApiRequest<PaymentsListPayload, PaidPayment[]> = (
  fetch,
  payload,
  signal
) => {
  // TODO: implement pagination https://apron.atlassian.net/browse/GEN-3903
  const DEFAULT_PAGE_SIZE = 100000
  return fetch
    .get('/payments', { signal, params: { pageSize: DEFAULT_PAGE_SIZE, ...payload } })
    .json<PaymentsListResponse<PaidPayment>>()
    .then((data) => data.payments)
}

export const getPendingPaymentsList: ApiRequest<PaymentsListPayload, PendingPayment[]> = (
  fetch,
  payload,
  signal
) =>
  fetch
    .get('/payments/pending', { signal, params: payload })
    .json<PaymentsListResponse<PendingPayment>>()
    .then((data) => data.payments)

export const getPaymentProgress: ApiRequest<
  PaymentProgressPayload,
  PaymentProgressResponse
> = (fetch, payload, signal) =>
  fetch
    .get(`/payments/${payload.paymentId}/progress`, { signal })
    .json<PaymentProgressResponse>()

export const getPaymentFundingAccount: ApiRequest<
  PaymentFundingAccountPayload,
  PaymentFundingAccount
> = (fetch, payload, signal) =>
  fetch
    .get(`/payments/funding-account-details`, { signal, params: payload })
    .json<PaymentFundingAccount>()

export const getPaymentsTopupCurrencies: ApiRequest<void, CurrencyCode[]> = (
  fetch,
  signal
) => fetch.get(`/payments/topup-currencies`, { signal }).json<CurrencyCode[]>()

export const getPaymentStats: ApiRequest<PaymentStatsPayload, PaymentStats> = (
  fetch,
  payload,
  signal
) => fetch.get(`/payments/stats`, { params: payload, signal }).json<PaymentStats>()

export const getCompaniesPaymentStats: ApiRequest<void, CompaniesPaymentStatsResponse> = (
  fetch,
  signal
) => fetch.get(`/payments/stats`, { signal }).json<CompaniesPaymentStatsResponse>()

function handleCreateCardPaymentError(error: unknown): never {
  if (
    error instanceof ExtendedResponseError &&
    error.meta.errorCode === ErrorCode.CKO_PAYMENT_DECLINED
  ) {
    if (error.meta.params.state === 'DECLINED') {
      throw new CardPaymentError('Card payment is declined', {
        state: error.meta.params.state,
      })
    }
  }

  throw error
}

export const createCardPayment: ApiRequest<
  CreateCardPaymentPayload,
  CardPaymentResponse
> = (fetch, { payrunId, instrumentId, challenge }) =>
  fetch
    .post(`/payruns/${payrunId}/topup-with-cko`, {
      json: { instrumentId, challenge },
    })
    .json<CardPaymentResponse>()
    .catch(handleCreateCardPaymentError)

export const createGetPaidCardPayment: ApiRequest<
  CreateGetPaidCardPaymentPayload,
  CardPaymentResponse
> = (fetch, { payrunId, token, challenge }) =>
  fetch
    .post(`/getpaid/payruns/${payrunId}/pay-with-cko`, {
      json: { token, challenge },
    })
    .json<CardPaymentResponse>()
    .catch(handleCreateCardPaymentError)

export const getCardPayment: ApiRequest<CardPaymentPayload, CardPaymentResponse> = (
  fetch,
  payload,
  signal
) =>
  fetch
    .get(`/payments/card/${payload.paymentId}`, { signal })
    .json<CardPaymentResponse>()
    .then((payment) => {
      if (payment.state === 'DECLINED') {
        throw new CardPaymentError('Card payment is declined', payment)
      }

      return payment
    })

export const createPlaidPayment: ApiRequest<
  CreatePlaidPaymentPayload,
  CreatePlaidPaymentResponse
> = (fetch, { payrunId, ...payload }) =>
  fetch
    .post(`/payruns/${payrunId}/topup-with-plaid`, { json: payload })
    .json<CreatePlaidPaymentResponse>()

export const createGetPaidPlaidPayment: ApiRequest<
  CreateGetPaidPlaidPaymentPayload,
  CreatePlaidPaymentResponse
> = (fetch, { payrunId, ...payload }) => {
  return fetch
    .post(`/getpaid/payruns/${payrunId}/pay-with-plaid`, { json: payload })
    .json<CreatePlaidPaymentResponse>()
}

export const createWalletPayment: ApiRequest<CreateWalletPaymentPayload, void> = (
  fetch,
  { payrunId, ...json }
) => fetch.post(`/payruns/${payrunId}/pay-with-wallet`, { json }).void()
