import { Slot } from '@radix-ui/react-slot'
import { FULLSTORY_EXCLUDE_CLASS, FULLSTORY_MASK_CLASS } from 'kitchen/constants'
import type React from 'react'

interface SensitiveProps {
  children: React.ReactNode
  behavior?: 'mask' | 'exclude' | 'ignore'
}

const getClassName = (behavior: 'mask' | 'exclude') => {
  switch (behavior) {
    case 'exclude':
      return FULLSTORY_EXCLUDE_CLASS
    case 'mask':
      return FULLSTORY_MASK_CLASS
  }
}

function Sensitive({ children, behavior = 'mask' }: SensitiveProps) {
  switch (behavior) {
    case 'ignore':
      return children
    default:
      return <Slot className={getClassName(behavior)}>{children}</Slot>
  }
}

export { Sensitive }
