import { initRudderAnalytics } from 'domains/analytics/rudderstack'
import { useHandleVitePreloadError } from 'kitchen/hooks/use-handle-vite-preload-error'
import { Suspense } from 'react'
import { AppProviders } from './app-providers'
import { AppRoutes } from './app-routes'
import { AppNotifications } from './components/app-notifications'
import { ErrorBoundary } from './components/error-boundary'
import { LoadingStatus } from './components/loading-status'
import { BugReport } from './features/bug-report/bug-report'
import { globalStyles } from './global-styles'

if (['production'].includes(process.env.BUILD_MODE) && !process.env.PLAYWRIGHT) {
  initRudderAnalytics()
}

export function App() {
  useHandleVitePreloadError()
  globalStyles()
  return (
    <AppProviders>
      <ErrorBoundary layout="flow-page">
        <Suspense fallback={<LoadingStatus layout="flow-page" delay={500} />}>
          <AppNotifications />
          <AppRoutes />
        </Suspense>
        {(() => {
          switch (process.env.BUILD_MODE) {
            case 'demo':
            case 'staging':
            case 'development':
              return <BugReport />
            default:
              return null
          }
        })()}
      </ErrorBoundary>
    </AppProviders>
  )
}
