import type { AccountsFees } from 'api/types/accounts'
import type { CurrencyCode, Money } from 'domains/money/types'

export const ACCOUNT_FEES: AccountsFees = {
  BANK: 0,
  CARD: 0.029,
  WALLET: 0,
}

// TODO: Migrate to endpoint
export const INTERNATIONAL_TRANSFER_FEES: Partial<Record<CurrencyCode, Money>> = {
  GBP: { amount: 300, currency: 'GBP' },
  USD: { amount: 300, currency: 'USD' },
  EUR: { amount: 300, currency: 'EUR' },
  AUD: { amount: 600, currency: 'AUD' },
  BHD: { amount: 200, currency: 'BHD' },
  BGN: { amount: 700, currency: 'BGN' },
  CAD: { amount: 600, currency: 'CAD' },
  CNY: { amount: 2700, currency: 'CNY' },
  CZK: { amount: 8500, currency: 'CZK' },
  DKK: { amount: 2600, currency: 'DKK' },
  HKD: { amount: 2900, currency: 'HKD' },
  HUF: { amount: 131400, currency: 'HUF' },
  INR: { amount: 30600, currency: 'INR' },
  IDR: { amount: 5853400, currency: 'IDR' },
  ILS: { amount: 1500, currency: 'ILS' },
  JPY: { amount: 55100, currency: 'JPY' },
  KES: { amount: 55200, currency: 'KES' },
  KWD: { amount: 200, currency: 'KWD' },
  MYR: { amount: 1800, currency: 'MYR' },
  MXN: { amount: 6700, currency: 'MXN' },
  NZD: { amount: 700, currency: 'NZD' },
  NOK: { amount: 4100, currency: 'NOK' },
  OMR: { amount: 200, currency: 'OMR' },
  PHP: { amount: 20900, currency: 'PHP' },
  PLN: { amount: 1600, currency: 'PLN' },
  QAR: { amount: 1400, currency: 'QAR' },
  RON: { amount: 1800, currency: 'RON' },
  SAR: { amount: 1400, currency: 'SAR' },
  SGD: { amount: 600, currency: 'SGD' },
  ZAR: { amount: 7000, currency: 'ZAR' },
  SEK: { amount: 4100, currency: 'SEK' },
  CHF: { amount: 400, currency: 'CHF' },
  THB: { amount: 13400, currency: 'THB' },
  TRY: { amount: 10400, currency: 'TRY' },
  UGX: { amount: 1376100, currency: 'UGX' },
  AED: { amount: 1400, currency: 'AED' },
}
