import { Locale } from 'kitchen/constants'
import { toDate, diffDays } from 'kitchen/utils/native-date'
import { FormattedMessage } from 'react-intl'
import { Surface, ProgressBar } from 'salad/components'
import { VStack, Text } from 'salad/primitives'
import { theme } from 'salad/stitches'
import type { SubscriptionAddon } from '../../types'

interface PaymentsFreeTrialBannerProgressProps {
  addon: SubscriptionAddon
}

export function PaymentsFreeTrialBannerProgress({
  addon,
}: PaymentsFreeTrialBannerProgressProps) {
  const startDate = toDate(addon.startDate)
  const endDate = toDate(addon.endDate)
  const left = diffDays(endDate, Locale.TODAY)
  const total = diffDays(endDate, startDate)
  const value = Math.max(Math.min(total - left + 1, total), 1)

  return (
    <Surface
      variant="snippet"
      style={{
        textAlign: 'start',
        borderRadius: theme.radii[8].toString(),
      }}
    >
      <VStack gap={8} p={12}>
        <Text as="h4" variant="title-14">
          <FormattedMessage
            id="billing.payments-free-trial-progress-banner.title"
            defaultMessage="Unlimited Payments"
          />
        </Text>
        <VStack gap={4}>
          <Text as="p" variant="paragraph-12">
            {addon.state === 'ACTIVE' ? (
              <FormattedMessage
                id="billing.payments-free-trial-progress-banner.active"
                defaultMessage="Free trial active"
              />
            ) : (
              <FormattedMessage
                id="billing.payments-free-trial-progress-banner.ended"
                defaultMessage="Free trial ended"
              />
            )}
          </Text>
          <ProgressBar value={value} total={total} />
          <Text as="p" variant="paragraph-12" weight={700}>
            <FormattedMessage
              id="billing.payments-free-trial-progress-banner.days"
              defaultMessage="Day {value} of {total}"
              values={{ value, total }}
            />
          </Text>
        </VStack>
      </VStack>
    </Surface>
  )
}
