import type { GenericForwardRefComponent } from 'kitchen/types'
import { ImpossibleError } from 'kitchen/utils/error'
import { forwardRef } from 'react'
import * as Icons from '../../icons'
import { Flex, Text } from '../../primitives'
import { theme } from '../../stitches'
import * as Button from '../button'
import { IconButton, type IconButtonProps } from '../icon-button'
import { Label } from '../label'

interface SurfaceHeaderProps {
  children?: React.ReactNode
}

function SurfaceHeader({ children }: SurfaceHeaderProps) {
  return (
    <Flex gap={8} css={{ alignItems: 'start', flexWrap: 'wrap' }}>
      {children}
    </Flex>
  )
}

interface SurfaceHeaderTitleProps extends Omit<React.HTMLAttributes<unknown>, 'color'> {
  variant?: 'heading' | 'label'
  children?: React.ReactNode
}

function SurfaceHeaderTitle({
  variant = 'heading',
  children,
  ...rest
}: SurfaceHeaderTitleProps) {
  switch (variant) {
    case 'heading':
      return (
        <Text
          as="h3"
          variant="headline-h2"
          color="black"
          css={{
            flex: '1 1 auto',
          }}
          {...rest}
        >
          {children}
        </Text>
      )
    case 'label':
      return (
        <Label
          as="h3"
          css={{
            flex: '1 1 auto',
          }}
          {...rest}
        >
          {children}
        </Label>
      )
    default:
      throw new ImpossibleError('Unhandled variant', variant)
  }
}

interface SurfaceHeaderDescriptionProps
  extends React.ComponentPropsWithoutRef<typeof Text> {}

function SurfaceHeaderDescription(props: SurfaceHeaderDescriptionProps) {
  return (
    <Text
      as="p"
      variant="paragraph-16"
      color="grey-60"
      css={{ flex: '1 0 auto', minWidth: 0, width: '100%' }}
      {...props}
    />
  )
}

export interface SurfaceHeaderBackProps extends IconButtonProps {}

const SurfaceHeaderBack: GenericForwardRefComponent<SurfaceHeaderBackProps> = forwardRef(
  function SurfaceHeaderBack(props, ref) {
    return (
      <IconButton
        ref={ref}
        size={24}
        css={{ alignSelf: 'center', marginInlineEnd: theme.space[8] }}
        {...props}
      >
        <Icons.S24.Chevron variant="left" />
      </IconButton>
    )
  }
)

interface SurfaceHeaderActionProps extends Button.ButtonProps {}

const SurfaceHeaderAction: GenericForwardRefComponent<SurfaceHeaderActionProps> =
  forwardRef(function SurfaceHeaderAction({ children, css, ...rest }, ref) {
    return (
      <Button.Root
        ref={ref}
        variant="minor"
        size="extra-small"
        hug
        css={{ marginInlineStart: 'auto', ...css }}
        {...rest}
      >
        <Button.Content>{children}</Button.Content>
      </Button.Root>
    )
  })

export {
  SurfaceHeader as Root,
  SurfaceHeaderBack as Back,
  SurfaceHeaderTitle as Title,
  SurfaceHeaderDescription as Description,
  SurfaceHeaderAction as Action,
}
