import type { ApiRequest } from 'kitchen/types'
import type { SendAnalyticsEventPayload } from './types'

export const sendAnalyticsEvent: ApiRequest<SendAnalyticsEventPayload, void> = (
  fetch,
  payload
) => {
  const { data, anonymousId, ...rest } = payload
  return fetch
    .post('/apron/frontend-event', {
      json: {
        // put anonymousId into `data` while separate column is not ready on BE.
        // afterwards would go like userId, companyId etc.
        data: JSON.stringify({ ...data, anonymousId }),
        ...rest,
      },
    })
    .void()
}
