import type * as Currency from '@dinero.js/currencies'
import { isType } from 'kitchen/utils/helpers'
import { z } from 'zod'

export const CurrencyCodeSchema = z
  .string()
  .refine((code) => isType<keyof typeof Currency>(code))

export const MoneySchema = z.object({
  amount: z.number(),
  currency: CurrencyCodeSchema,
})

export const ScaledMoneySchema = MoneySchema.extend({
  scale: z.number().optional(),
})
