import { useHasFeatureFlag } from 'api/hooks/features'
import type { Company } from 'api/types/companies'
import { FormattedMessage } from 'react-intl'
import { Surface, Button } from 'salad/components'
import * as Icons from 'salad/icons'
import { ButtonBase, VStack, HStack, Text } from 'salad/primitives'
import { theme } from 'salad/stitches'

interface PaymentsFreeTrialBannerProps {
  company: Company
  onStart: () => void
}

export function PaymentsFreeTrialBanner({
  company,
  onStart,
}: PaymentsFreeTrialBannerProps) {
  const [hasXmasPaymentsFreeTrial] = useHasFeatureFlag(company.id, [
    'XMAS_PAYMENTS_FREE_TRIAL',
  ])

  if (hasXmasPaymentsFreeTrial && company.practices.length > 0) {
    return (
      <ButtonBase onClick={() => onStart()}>
        <HStack
          p={12}
          gap={8}
          css={{
            alignItems: 'center',
            borderRadius: theme.radii[8],
            backgroundColor: theme.colors['light-blue-20'],
            svg: { color: theme.colors['dark-blue'] },
          }}
        >
          <Icons.S24.Present />
          <VStack>
            <Text variant="title-14" color="black" align="start">
              <FormattedMessage
                id="billing.payments-free-trial-xmas-banner.title"
                defaultMessage="Holiday offer"
              />
            </Text>
            <Text variant="paragraph-12" color="grey-60" align="start">
              <FormattedMessage
                id="billing.payments-free-trial-xmas-banner.description"
                defaultMessage="Unwrap unlimited payments, offer ends soon"
              />
            </Text>
          </VStack>
        </HStack>
      </ButtonBase>
    )
  }

  return (
    <Surface variant="snippet" style={{ borderRadius: theme.radii[8].toString() }}>
      <VStack gap={8} p={12}>
        <Text as="h4" variant="title-14" align="center">
          <FormattedMessage
            id="billing.payments-free-trial-banner.title"
            defaultMessage="Try payments for free"
          />
        </Text>
        <Button.Root variant="common" size="extra-small" onClick={() => onStart()}>
          <Button.Content>
            <FormattedMessage
              id="billing.payments-free-trial-banner.start"
              defaultMessage="Learn more"
            />
          </Button.Content>
        </Button.Root>
      </VStack>
    </Surface>
  )
}
