import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { CacheKey, Time } from 'kitchen/constants'
import { useFetch } from 'kitchen/context/fetch'
import type {
  QueryHookFactory,
  QueryHookCallbackFactory,
  MutationHookFactory,
} from 'kitchen/types'
import { ApiError } from 'kitchen/utils/error'
import { immutableRequestOptions } from 'kitchen/utils/fetch'
import {
  getPricingPlan,
  getPricingPlansList,
  getSubscription,
  getSubscriptionUsage,
  getCompanySubscriptions,
  getBillingPaymentMethod,
  setupBillingPaymentMethod,
  subscribeToPricingPlan,
  cancelSubscription,
  getBillingDetails,
  getPracticeClientsSubscriptionsList,
  updateBillingDetails,
  updateSubscriberPayer,
  getBillingInvoices,
  generateBillingInvoicePDF,
  getBillingInvoiceLineItems,
  generateBillingInvoiceCSV,
  getPracticeClientsSubscriptionsInfo,
  startPaymentsFreeTrial,
  chargeBillingInvoice,
  sendXmasAddonToClients,
} from './requests'
import type {
  PricingPlan,
  CompanySubscription,
  SubscriptionUsage,
  SubscriptionUsagePayload,
  CompanySubscriptionsPayload,
  BillingPaymentMethod,
  BillingPaymentMethodPayload,
  SetupBillingPaymentMethodPayload,
  SetupBillingPaymentMethodResponse,
  SubscribeToPricingPlanPayload,
  CancelSubscriptionPayload,
  BillingDetails,
  BillingDetailsPayload,
  UpdateBillingDetailsPayload,
  SubscriptionsListPayload,
  PracticeClientSubscriptionsListResponse,
  UpdateSubscriberPayerPayload,
  BillingInvoice,
  BillingInvoicesPayload,
  GenerateBillingInvoicePDFPayload,
  BillingInvoiceLineItemsPayload,
  BillingInvoiceLineItem,
  GenerateBillingInvoiceCSVPayload,
  PracticeClientsSubscriptionsInfoPayload,
  PracticeClientsSubscriptionsInfo,
  PricingPlansListPayload,
  PricingPlanPayload,
  StartPaymentsFreeTrialPayload,
  BillingInvoiceIdPayload,
  SendXmasAddonToClientsPayload,
} from './types'

export const usePricingPlan: QueryHookFactory<PricingPlanPayload, PricingPlan> = (
  payload,
  options
) => {
  const fetch = useFetch()
  return useQuery({
    queryKey: [CacheKey.BILLING, CacheKey.PRICING_PLAN, payload.planId],
    queryFn: ({ signal }) => getPricingPlan(fetch, payload, signal),
    ...options,
  })
}

export const usePricingPlansList: QueryHookFactory<
  PricingPlansListPayload,
  PricingPlan[]
> = (payload, options) => {
  const fetch = useFetch()
  return useQuery({
    queryKey: [CacheKey.BILLING, CacheKey.PRICING_PLANS_LIST, payload.subscriberId],
    queryFn: ({ signal }) => getPricingPlansList(fetch, payload, signal),
    ...options,
  })
}

export const useCompanySubscriptions: QueryHookFactory<
  CompanySubscriptionsPayload,
  CompanySubscription[]
> = (payload, options) => {
  const fetch = useFetch()
  return useQuery({
    queryKey: [CacheKey.BILLING, CacheKey.SUBSCRIPTIONS, payload.subscriberId],
    queryFn: ({ signal }) => getCompanySubscriptions(fetch, payload, signal),
    ...immutableRequestOptions,
    ...options,
  })
}

export const useBillingPaymentMethod: QueryHookFactory<
  BillingPaymentMethodPayload,
  BillingPaymentMethod | null
> = (payload, options) => {
  const fetch = useFetch()
  return useQuery({
    queryKey: [CacheKey.BILLING, CacheKey.PAYMENT_METHOD, payload.subscriberId],
    queryFn: ({ signal }) => getBillingPaymentMethod(fetch, payload, signal),
    ...options,
  })
}

export const useSubscriptionUsage: QueryHookFactory<
  SubscriptionUsagePayload,
  SubscriptionUsage
> = (payload, options) => {
  const fetch = useFetch()
  return useQuery({
    queryKey: [CacheKey.BILLING, CacheKey.SUBSCRIPTIONS, payload.id, CacheKey.USAGE],
    queryFn: ({ signal }) => {
      if (payload.id === undefined) {
        throw new ApiError('subscription id is required')
      }
      return getSubscriptionUsage(fetch, payload, signal)
    },
    ...options,
  })
}

export const useSetupBillingPaymentMethod: MutationHookFactory<
  SetupBillingPaymentMethodPayload,
  SetupBillingPaymentMethodResponse
> = (options) => {
  const fetch = useFetch()
  return useMutation({
    mutationFn: (payload) => setupBillingPaymentMethod(fetch, payload),
    ...options,
  })
}

export const useBillingDetails: QueryHookFactory<
  BillingDetailsPayload,
  BillingDetails | null
> = (payload, options) => {
  const fetch = useFetch()
  return useQuery({
    queryKey: [CacheKey.BILLING, CacheKey.DETAILS, payload.id],
    queryFn: ({ signal }) => getBillingDetails(fetch, payload, signal),
    ...options,
  })
}

export const useUpdateBillingDetails: MutationHookFactory<
  UpdateBillingDetailsPayload,
  void
> = (options) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()
  return useMutation({
    ...options,
    mutationFn: (payload) => updateBillingDetails(fetch, payload),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([
        CacheKey.BILLING,
        CacheKey.DETAILS,
        variables.id,
      ])

      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useSubscribeToPricingPlan: MutationHookFactory<
  SubscribeToPricingPlanPayload,
  CompanySubscription
> = (options) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation({
    ...options,
    mutationFn: async (payload) => {
      const createdSubscription = await subscribeToPricingPlan(fetch, payload)
      return getSubscription(fetch, createdSubscription)
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([CacheKey.COMPANIES])
      await queryClient.invalidateQueries([CacheKey.BILLING, CacheKey.SUBSCRIPTIONS])
      await queryClient.invalidateQueries([
        CacheKey.PRACTICE,
        CacheKey.BILLING,
        CacheKey.SUBSCRIPTIONS,
      ])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useBatchSubscribeToPricingPlan: MutationHookFactory<
  SubscribeToPricingPlanPayload[],
  CompanySubscription[]
> = (options) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation({
    ...options,
    mutationFn: (payloads) =>
      Promise.all(
        payloads.map(async (payload) => {
          const createdSubscription = await subscribeToPricingPlan(fetch, payload)
          return getSubscription(fetch, createdSubscription)
        })
      ),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([CacheKey.COMPANIES])
      await queryClient.invalidateQueries([CacheKey.BILLING, CacheKey.SUBSCRIPTIONS])
      await queryClient.invalidateQueries([
        CacheKey.PRACTICE,
        CacheKey.BILLING,
        CacheKey.SUBSCRIPTIONS,
      ])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useSubscribeToPricingPlanCallback: QueryHookCallbackFactory<
  SubscribeToPricingPlanPayload,
  CompanySubscription
> = (payload, mutationOptions, queryOptions) => {
  const subscribeToPricingPlan = useSubscribeToPricingPlan(mutationOptions)

  return useQuery({
    queryKey: [
      CacheKey.BILLING,
      CacheKey.SUBSCRIBE,
      payload.subscriberId,
      payload.pricingPlanId,
    ],
    queryFn: () => subscribeToPricingPlan.mutateAsync(payload),
    cacheTime: Time.MINUTE,
    staleTime: Time.SECOND,
    ...queryOptions,
  })
}

export const useCancelSubscription: MutationHookFactory<
  CancelSubscriptionPayload,
  void
> = (options) => {
  const fetch = useFetch()
  return useMutation({
    mutationFn: (payload) => cancelSubscription(fetch, payload),
    ...options,
  })
}

export const usePracticeClientSubscriptionsList: QueryHookFactory<
  SubscriptionsListPayload,
  PracticeClientSubscriptionsListResponse
> = (payload, options) => {
  const fetch = useFetch()

  return useQuery({
    queryKey: [
      CacheKey.PRACTICE,
      CacheKey.BILLING,
      CacheKey.SUBSCRIPTIONS,
      payload.practiceId,
      payload.page,
      payload.pageSize,
    ],
    queryFn: ({ signal }) => getPracticeClientsSubscriptionsList(fetch, payload, signal),
    ...options,
  })
}

export const usePracticeClientsSubscriptionsInfo: QueryHookFactory<
  PracticeClientsSubscriptionsInfoPayload,
  PracticeClientsSubscriptionsInfo[]
> = (payload, options) => {
  const fetch = useFetch()

  return useQuery({
    queryKey: [
      CacheKey.PRACTICE,
      CacheKey.BILLING,
      CacheKey.SUBSCRIPTIONS,
      CacheKey.START_DATES,
      payload,
    ],
    queryFn: ({ signal }) => getPracticeClientsSubscriptionsInfo(fetch, payload, signal),
    ...options,
  })
}

export const useUpdateSubscriberPayer: MutationHookFactory<
  UpdateSubscriberPayerPayload,
  void
> = (options) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation({
    ...options,
    mutationFn: (payload) => updateSubscriberPayer(fetch, payload),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([CacheKey.COMPANIES])
      await queryClient.invalidateQueries([
        CacheKey.BILLING,
        CacheKey.SUBSCRIPTIONS,
        variables.subscriberId,
      ])
      await queryClient.invalidateQueries([
        CacheKey.PRACTICE,
        CacheKey.BILLING,
        CacheKey.SUBSCRIPTIONS,
      ])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useBatchUpdateSubscriberPayer: MutationHookFactory<
  UpdateSubscriberPayerPayload[],
  null
> = (options) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation({
    ...options,
    async mutationFn(payloads) {
      await Promise.all(payloads.map((payload) => updateSubscriberPayer(fetch, payload)))
      return null
    },
    async onSuccess(data, variables, context) {
      await queryClient.invalidateQueries([CacheKey.COMPANIES])
      await queryClient.invalidateQueries([CacheKey.BILLING, CacheKey.SUBSCRIPTIONS])
      await queryClient.invalidateQueries([
        CacheKey.PRACTICE,
        CacheKey.BILLING,
        CacheKey.SUBSCRIPTIONS,
      ])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useBatchUpdateSubscriberPayerCallback: QueryHookCallbackFactory<
  UpdateSubscriberPayerPayload[],
  null
> = (payload, mutationOptions, queryOptions) => {
  const batchUpdateSubscriberPayer = useBatchUpdateSubscriberPayer(mutationOptions)
  return useQuery({
    queryKey: [
      CacheKey.PRACTICE,
      CacheKey.BILLING,
      CacheKey.CALLBACK,
      CacheKey.SUBSCRIPTIONS,
      payload,
    ],
    queryFn: () => batchUpdateSubscriberPayer.mutateAsync(payload),
    enabled: payload.length > 0,
    cacheTime: Time.MINUTE,
    staleTime: Time.SECOND,
    ...queryOptions,
  })
}

export const useBillingInvoices: QueryHookFactory<
  BillingInvoicesPayload,
  BillingInvoice[]
> = (payload, queryOptions) => {
  const fetch = useFetch()
  return useQuery({
    queryKey: [CacheKey.BILLING, CacheKey.INVOICES, payload.payerId],
    queryFn: ({ signal }) => getBillingInvoices(fetch, payload, signal),
    ...queryOptions,
  })
}

export const useBillingInvoicesLineItems: QueryHookFactory<
  BillingInvoiceLineItemsPayload,
  BillingInvoiceLineItem[]
> = (payload, queryOptions) => {
  const fetch = useFetch()
  return useQuery({
    queryKey: [CacheKey.INVOICES_LINE_ITEMS, payload.id],
    queryFn: ({ signal }) => getBillingInvoiceLineItems(fetch, payload, signal),
    ...queryOptions,
  })
}

export const useGenerateBillingInvoicePDF: MutationHookFactory<
  GenerateBillingInvoicePDFPayload,
  URL
> = (options) => {
  const fetch = useFetch()
  return useMutation({
    mutationFn: (payload) => generateBillingInvoicePDF(fetch, payload),
    ...options,
  })
}

export const useGenerateBillingInvoiceCSV: MutationHookFactory<
  GenerateBillingInvoiceCSVPayload,
  string
> = (options) => {
  const fetch = useFetch()
  return useMutation({
    mutationFn: (payload) => generateBillingInvoiceCSV(fetch, payload),
    ...options,
  })
}

export const useChargeBillingInvoices: MutationHookFactory<
  BillingInvoiceIdPayload[],
  void
> = (options) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()
  return useMutation({
    ...options,
    mutationFn: async (payloads) => {
      await Promise.all(payloads.map((id) => chargeBillingInvoice(fetch, id)))
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([CacheKey.BILLING, CacheKey.INVOICES])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useStartPaymentsFreeTrial: MutationHookFactory<
  StartPaymentsFreeTrialPayload,
  void
> = (options) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload) => startPaymentsFreeTrial(fetch, payload),
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([CacheKey.COMPANIES])
      await queryClient.invalidateQueries([CacheKey.BILLING, CacheKey.SUBSCRIPTIONS])
      await queryClient.invalidateQueries([
        CacheKey.PRACTICE,
        CacheKey.BILLING,
        CacheKey.SUBSCRIPTIONS,
      ])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useSendXmasAddonToClients: MutationHookFactory<
  SendXmasAddonToClientsPayload,
  void
> = (options) => {
  const fetch = useFetch()

  return useMutation({
    mutationFn: (payload) => sendXmasAddonToClients(fetch, payload),
    ...options,
  })
}
