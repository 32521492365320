import { NuqsAdapter } from 'nuqs/adapters/react-router'
import type { RouterProviderProps } from 'react-router-dom'
import { RouterProvider as ReactRouterProvider } from 'react-router-dom'

export const RouterProvider = (props: RouterProviderProps) => {
  return (
    <NuqsAdapter>
      <ReactRouterProvider {...props} />
    </NuqsAdapter>
  )
}
