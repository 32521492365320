import { useEffect } from 'react'

export function useHandleVitePreloadError() {
  useEffect(() => {
    /**
     * This is from official vite guide: https://vitejs.dev/guide/build#load-error-handling
     * In case we fail to import dynamic modules, we will force reload the page
     * Could be fixed in future versions of vite.
     * Discussion in Slack: https://team-apron.slack.com/archives/C066WA0LCSX/p1721223463297569
     * Task: FT-7
     */

    const handlePreloadError = (event: Event) => {
      event.preventDefault()
      window.location.reload()
    }

    window.addEventListener('vite:preloadError', handlePreloadError)
    return () => window.removeEventListener('vite:preloadError', handlePreloadError)
  }, [])
}
