import type { GenericComponent } from 'kitchen/types'
import { Logo, ScrollArea } from '../../components'
import { VStack, type StackProps } from '../../primitives'
import { theme } from '../../stitches'

export interface SidebarLayoutProps extends StackProps {}

const SidebarLayout = ({ css, ...rest }: SidebarLayoutProps) => (
  <ScrollArea
    variant="native"
    css={{
      backgroundColor: theme.colors['white'],
      borderInlineEndWidth: 1,
      borderInlineEndStyle: 'solid',
      borderInlineEndColor: theme.colors['grey-5'],
      width: 240,
      height: '100%',
    }}
  >
    <VStack
      css={{
        gridTemplate: `
          "start" 40px
          ".    " 24px
          "tabs " 1fr
          "end  " minmax(48px, auto) / auto
        `,
        alignItems: 'start',
        minHeight: '100%',
        paddingBlockStart: theme.space[24],
        paddingBlockEnd: theme.space[16],
        paddingInline: theme.space[12],
        ...css,
      }}
      {...rest}
    />
  </ScrollArea>
)

export interface SidebarLayoutLogoProps extends StackProps {}

const SidebarLayoutLogo: GenericComponent<SidebarLayoutLogoProps> = ({
  css,
  ...rest
}) => (
  <VStack css={{ gridArea: 'start', color: theme.colors['black'], ...css }} {...rest}>
    <Logo variant="small" />
  </VStack>
)

export interface SidebarLayoutTabsProps {
  children?: React.ReactNode
}

const SidebarLayoutTabs = ({ children }: SidebarLayoutTabsProps) => (
  <VStack css={{ gridArea: 'tabs' }}>{children}</VStack>
)

export interface SidebarLayoutEndProps {
  children?: React.ReactNode
}

const SidebarLayoutEnd = ({ children }: SidebarLayoutEndProps) => (
  <VStack
    gap={12}
    css={{
      gridArea: 'end',
      alignSelf: 'center',
    }}
  >
    {children}
  </VStack>
)

export {
  SidebarLayout as Root,
  SidebarLayoutLogo as Logo,
  SidebarLayoutTabs as Tabs,
  SidebarLayoutEnd as End,
}
