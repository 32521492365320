export const Present = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 11V8H13.2335H15.8149H20V11H13ZM18.8128 6H20.5C21.3284 6 22 6.67157 22 7.5V11.5C22 12.1531 21.5826 12.7087 21 12.9146V18C21 19.6569 19.6569 21 18 21H12H6C4.34315 21 3 19.6569 3 18V12.9146C2.4174 12.7087 2 12.1531 2 11.5V7.5C2 6.67157 2.67157 6 3.5 6H5.18722C4.80538 4.98453 4.98428 3.77879 5.94034 2.8789C7.28201 1.61607 9.4949 1.72855 10.6802 3.12311L12 4.67594L13.3198 3.12311C14.5052 1.72855 16.718 1.61607 18.0597 2.8789C19.0157 3.77879 19.1946 4.98453 18.8128 6ZM15.8149 6C16.4134 6 16.7719 5.68507 16.9209 5.34498C17.0636 5.01903 17.0322 4.65838 16.6889 4.33526L13.4994 6L16.6889 4.33524C16.1683 3.84526 15.2772 3.9085 14.8437 4.41835L14.8437 4.41841L13.4994 6H15.8149ZM10.7665 8H8.18518H4V11H11V8H10.7665ZM7.07913 5.34497C7.22808 5.68506 7.5866 6 8.18518 6H10.5006L9.15627 4.41836L9.15627 4.41835C8.72294 3.90853 7.83174 3.84522 7.31112 4.33525C6.96782 4.65838 6.93638 5.01903 7.07913 5.34497ZM11 19H6C5.44772 19 5 18.5523 5 18V13H11V19ZM13 19V13H19V18C19 18.5523 18.5523 19 18 19H13Z"
      fill="currentColor"
    />
  </svg>
)
