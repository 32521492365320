import { useSendAnalyticsEvent } from 'domains/analytics/queries'
import { createCancellablePromise } from 'kitchen/utils/async'
import { useEffect } from 'react'

export function useTrackPageVisit(pathname: string) {
  const { mutateAsync: sendAnalyticsEvent } = useSendAnalyticsEvent()

  useEffect(() => {
    const promise = createCancellablePromise(() =>
      sendAnalyticsEvent({
        event: 'PAGE_VISIT',
        data: { pathname },
      })
    )

    return () => {
      promise.abort('Cancelled')
    }
  }, [sendAnalyticsEvent, pathname])
}
