import * as Sentry from '@sentry/react'
import { SESSION_ID } from 'domains/analytics/constants'
import { refreshAuthSession } from 'domains/auth/requests'
import type { AuthSession } from 'domains/auth/types'
import type { FetchErrorMeta } from 'kitchen/types'
import { ErrorCode } from 'kitchen/types'
import * as YF from 'ya-fetch'
import { CacheKey } from '../constants'
import { settled } from './async'
import { ApiError, ExtendedResponseError } from './error'
import { randomString } from './helpers'
import { queryClient } from './query-client'

export const publicApi = YF.create({
  resource: process.env.API_URL,
  async onRequest(_, options) {
    options.headers.set('Session-Id', SESSION_ID)
    options.headers.set('X-Request-Id', randomString(10))
  },
  async onFailure(error) {
    if (error instanceof YF.ResponseError) {
      const errorMeta = await settled<FetchErrorMeta>(error.response.json())
      if (errorMeta.status === 'fulfilled') {
        throw new ExtendedResponseError(error.response, errorMeta.value)
      } else {
        throw error
      }
    }
    Sentry.captureException(error, { tags: { type: 'network_call' } })
    throw error
  },
})

export const authorizedApi = publicApi.extend({
  async onRequest(_, options) {
    const authSession = await queryClient.ensureQueryData<AuthSession>({
      queryKey: [CacheKey.AUTH],
      queryFn: () => refreshAuthSession(publicApi),
    })

    options.headers.set('Authorization', `Bearer ${authSession.accessToken}`)
    options.headers.set('Session-Id', SESSION_ID)
    options.headers.set('X-Request-Id', randomString(10))
  },
  async onFailure(error) {
    if (error instanceof YF.ResponseError) {
      const errorMeta = await settled<FetchErrorMeta>(error.response.json())
      if (errorMeta.status === 'rejected') {
        throw error
      }

      // try refresh token
      if (
        error.response.status === 401 &&
        errorMeta.value.errorCode === ErrorCode.UNAUTHORIZED
      ) {
        try {
          await queryClient.refetchQueries<AuthSession>(
            { queryKey: [CacheKey.AUTH], exact: true },
            { throwOnError: true, cancelRefetch: false }
          )
        } catch (refetchError) {
          if (
            refetchError instanceof YF.ResponseError ||
            refetchError instanceof ApiError
          ) {
            throw refetchError
          }

          /**
           * Possible reasons:
           * - No auth queries left mounted
           * - Request has been cancelled
           * - Internet connection has been dropped
           *
           * In either case we shouldn't retry.
           **/
          throw error
        }

        return YF.request(error.response.options)
      }

      throw new ExtendedResponseError(error.response, errorMeta.value)
    }
    if (error instanceof Error && error.name === 'AbortError') {
      throw error
    }
    Sentry.captureException(error, { tags: { type: 'network_call' } })
    throw error
  },
})
