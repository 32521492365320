import { createContext, useContext } from 'react'
import type * as YF from 'ya-fetch'
import { authorizedApi } from '../utils/api'

type FetchContextValue = YF.Instance<YF.Payload>

const FetchContext = createContext<FetchContextValue | undefined>(undefined)

export const FetchProvider = ({ children }: { children: React.ReactNode }) => (
  <FetchContext.Provider value={authorizedApi}>{children}</FetchContext.Provider>
)

/**
 * Returns `ya-fetch` instance
 */
export function useFetch() {
  const context = useContext(FetchContext)
  if (context === undefined) {
    throw new Error('useFetch must be used within a FetchProvider')
  }
  return context
}
