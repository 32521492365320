import { theme } from 'salad/stitches'
import { normalize } from 'stitches-normalize-css'

export const sharedGlobalStyles = [
  ...normalize,
  {
    html: {
      boxSizing: 'border-box',
      height: '100%',
      color: theme.colors['page-text'],
      backgroundColor: theme.colors['page-background'],
      transition: 'background 150ms ease-in-out',
      scrollbarGutter: 'stable',
    },
    body: {
      fontFamily: 'sans-serif',
      height: '100%',
      margin: '0',
      '-moz-osx-font-smoothing': 'grayscale',
      '-webkit-font-smoothing': 'antialiased',
    },
    '*, *::before, *::after': {
      boxSizing: 'inherit',
    },
    'h1, h2, h3, h4, h5, h6, p': {
      margin: '0',
    },
    button: {
      appearance: 'none',
      border: 'none',
      background: 'none',
      borderRadius: '0',
      padding: '0',
    },
    a: {
      textDecoration: 'none',
      color: 'inherit',
    },
    'ol, ul': {
      listStyle: 'none',
      margin: '0',
      padding: '0',
    },
    'img, svg': {
      display: 'block',
    },
    // remove blue background on autofill in chrome
    'input, select, textarea': {
      '&:-webkit-autofill': {
        transitionDelay: '9999s',
      },
    },
    'a, button': {
      '&:focus-visible': {
        outline: 'none',
        paddedOutline: theme.colors['light-blue'],
      },
    },
  },
] as const
