import { match } from 'kitchen/router'

import * as Segment from './segment'

export const ACCOUNTING_APP = match(Segment.ACCOUNTING_APP, Segment.ALL)
export const ADVANCED_BOOKKEEPING = match(Segment.ADVANCED_BOOKKEEPING, Segment.ALL)
export const ALL = match(Segment.ALL)
export const APPROVED = match(Segment.APPROVED)
export const AUTH = match(Segment.AUTH, Segment.ALL)
export const AUTH_SETUP_2FA = match(Segment.AUTH, Segment.SETUP_2FA)
export const AUTH_SETUP_PASSWORD = match(Segment.AUTH, Segment.SETUP_PASSWORD)
export const BANK_DETAILS = match(Segment.BANK_DETAILS)
export const BANK_FEED = match(Segment.BANK_FEED, Segment.ALL)
export const BATCH = match(Segment.BATCH)
export const BILLING = match(Segment.BILLING)
export const BILLING_SETUP_CANCELLED = match(
  Segment.BILLING,
  Segment.SETUP,
  Segment.CANCELLED
)
export const BOOKKEEPING = match(Segment.BOOKKEEPING, Segment.ALL)
export const CALLBACK = match(Segment.CALLBACK)
export const CANCEL = match(Segment.CANCEL)
export const CARD_CHALLENGE = match(Segment.CARD_CHALLENGE, Segment.STATUS)
export const CATEGORIES = match(Segment.CATEGORIES, Segment.ALL)
export const COMMENTS = match(Segment.COMMENTS)
export const COMMENT_ATTACHMENT = match(Segment.COMMENT_ATTACHMENT)
export const COMPANIES = match(Segment.COMPANIES, Segment.ALL)
export const COMPANY = match(Segment.COMPANY)
export const COMPANY_ID = match(Segment.COMPANY_ID, Segment.ALL)
export const CONNECT = match(Segment.CONNECT)
export const CONNECT_EMAIL = match(Segment.CONNECT, Segment.EMAIL)
export const CONNECT_VENDOR = match(Segment.CONNECT, Segment.SOURCE)
export const CONTACTS = match(Segment.CONTACTS, Segment.ALL)
export const CONTACT_ID = match(Segment.CONTACT_ID, Segment.ALL)
export const CUSTOMER_RULES = match(Segment.CUSTOMER_RULES)
export const DASHBOARD = match(Segment.DASHBOARD)
export const DELETED = match(Segment.DELETED)
export const DETAILS = match(Segment.DETAILS, Segment.ALL)
export const DIRECT_EMAILS = match(Segment.DIRECT_EMAILS, Segment.ALL)
export const DOCUMENT = match(Segment.DOCUMENTS, Segment.DOCUMENT_ID)
export const DOCUMENTS = match(Segment.DOCUMENTS)
export const DOCUMENTS_ARCHIVED = match(Segment.DOCUMENTS, Segment.ARCHIVED)
export const DOCUMENTS_INBOX = match(Segment.DOCUMENTS, Segment.INBOX)
export const DOCUMENTS_PUBLISHED = match(Segment.DOCUMENTS, Segment.PUBLISHED)
export const DOCUMENTS_READY_TO_EXPORT = match(Segment.DOCUMENTS, Segment.READY_TO_EXPORT)
export const EMAIL = match(Segment.EMAIL, Segment.ALL)
export const EMAIL_CHAIN = match(Segment.EMAIL_CHAIN)
export const EXPENSES = match(Segment.EXPENSES, Segment.ALL)
export const FAILED_TO_APPROVE = match(Segment.FAILED_TO_APPROVE)
export const FAILED_TO_DELETE = match(Segment.FAILED_TO_DELETE)
export const GENERAL = match(Segment.GENERAL)
export const GET_STARTED = match(Segment.GET_STARTED)
export const HUB = match(Segment.HUB, Segment.ALL)
export const INDEX = `/`
export const INVITE = match(Segment.INVITE)
export const INVOICES = match(Segment.INVOICES)
export const INVOICE_SOURCE = match(Segment.INVOICE_SOURCE, Segment.INVOICE_ID)
export const LINE_ITEMS = match(Segment.LINE_ITEMS)
export const LISTS = match(Segment.LISTS, Segment.ALL)
export const MANAGE = match(Segment.MANAGE, Segment.ALL)
export const MANAGE_INVOICE_DETAILS = match(
  Segment.MANAGE,
  Segment.INVOICES,
  Segment.INVOICE_ID
)
export const MANUAL_PAYMENT = match(Segment.MANUAL_PAYMENT)
export const MEMBERS = match(Segment.MEMBERS, Segment.ALL)
export const NEW = match(Segment.NEW)
export const ONBOARDING = match(Segment.ONBOARDING)
export const ONBOARDING_COMPANY_SYNC = match(Segment.ONBOARDING, Segment.COMPANY_SYNC)
export const PAID = match(Segment.PAID, Segment.OPTIONAL_PAYMENT_ID)
export const PAY = match(Segment.PAY, Segment.ALL)
export const PAYMENTS = match(Segment.PAYMENTS)
export const PAYMENT_LINK = match(Segment.PAYMENT, Segment.PAYRUN_ID, Segment.ALL)
export const PAYMENT_VIEW = match(Segment.PAYMENT, Segment.PAYRUN_ID)
export const PAYRUN_AUDIT_LOG = match(Segment.AUDIT_LOG, Segment.PAYRUN_ID)
export const PENDING = match(Segment.PENDING, Segment.OPTIONAL_PAYMENT_ID)
export const PERMISSIONS_REQUEST_APPROVE = match(
  Segment.PERMISSIONS_REQUEST,
  Segment.APPROVE
)
export const PLAN = match(Segment.PLAN)
export const PLANS = match(Segment.PLANS)
export const PRACTICE = match(Segment.PRACTICE)
export const PRACTICE_MANAGE_INVOICES = match(Segment.INVOICES, Segment.INVOICE_ID)
export const PRACTICE_ONBOARDING = match(Segment.ONBOARDING, Segment.PRACTICE)
export const PRODUCTS_AND_SERVICES = match(Segment.PRODUCTS_AND_SERVICES, Segment.ALL)
export const PROFILE = match(Segment.PROFILE)
export const REMITTANCE_ADVICE = match(Segment.REMITTANCE_ADVICE, Segment.PAYRUN_ID)
export const REVIEW = match(Segment.REVIEW)
export const REVIEW_PAYMENT_LINK = match(Segment.REVIEW_PAYMENT_LINK, Segment.PAYRUN_ID)
export const RULES = match(Segment.RULES)
export const SALES_DOCUMENT = match(
  Segment.SALES_DOCUMENTS,
  Segment.DOCUMENT_ID,
  Segment.ALL
)
export const SALES_DOCUMENTS = match(Segment.SALES_DOCUMENTS)
export const SALES_DOCUMENTS_ARCHIVED = match(Segment.SALES_DOCUMENTS, Segment.ARCHIVED)
export const SALES_DOCUMENTS_INBOX = match(Segment.SALES_DOCUMENTS, Segment.INBOX)
export const SALES_DOCUMENTS_PUBLISHED = match(Segment.SALES_DOCUMENTS, Segment.PUBLISHED)
export const SALES_DOCUMENTS_READY_TO_EXPORT = match(
  Segment.SALES_DOCUMENTS,
  Segment.READY_TO_EXPORT
)
export const SECURITY = match(Segment.SECURITY)
export const SELECT = match(Segment.SELECT)
export const SETTINGS = match(Segment.SETTINGS, Segment.ALL)
export const STAKEHOLDERS = match(Segment.STAKEHOLDERS)
export const SUBSCRIBE_PLAN_ID_WITH_PAYER = match(
  Segment.SUBSCRIBE,
  Segment.PLAN_ID,
  Segment.PAYER_ID
)
export const SUBSCRIPTION = match(Segment.SUBSCRIPTION, Segment.ALL)
export const SUCCESS = match(Segment.SUCCESS)
export const SUPPLIER_RULES = match(Segment.SUPPLIER_RULES)
export const SYNC_MODE = match(Segment.SYNC_MODE)
export const TAX_RATES = match(Segment.TAX_RATES, Segment.ALL)
export const TEAM = match(Segment.TEAM, Segment.ALL)
export const TRACKING_CATEGORY_OPTIONS = match(
  Segment.TRACKING_CATEGORIES,
  Segment.TRACKING_CATEGORY_NAME,
  Segment.OPTIONS,
  Segment.ALL
)
export const TRANSACTION_ID = match(Segment.TRANSACTION_ID)
export const USER_ID = match(Segment.USER_ID)
export const USER_ONBOARDING = match(Segment.ONBOARDING, Segment.USER)
export const USER_SETTINGS = match(Segment.USER, Segment.SETTINGS, Segment.ALL)
export const VERIFY_DOCUMENTS = match(Segment.VERIFY, Segment.IDENTITIES)
export const WORKFLOW = match(Segment.WORKFLOW)
