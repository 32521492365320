import { sharedGlobalStyles } from 'salad/shared-global-styles'
import { globalCss } from 'salad/stitches'

export const globalStyles = globalCss(...sharedGlobalStyles, {
  '#root, #storybook-root': {
    height: '100%',
    paddingTop: 'var(--app-header-offset, 0px)',
    '&.blurred': {
      filter: 'blur(4px)',
    },
  },
  // allows usage of Intercom on top of modals
  '.intercom-lightweight-app': {
    'pointer-events': 'all',
  },
})
