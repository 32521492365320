import { theme } from '../../stitches'

export const Success = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
    <circle
      opacity="0.9"
      cx="32"
      cy="32"
      r="32"
      fill={theme.colors['light-green-60'].toString()}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50 19.9106L28.8708 46L14 30L17.9655 26L28.8708 36L45.7243 16L50 19.9106Z"
      fill="black"
    />
  </svg>
)
