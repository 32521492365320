import { motion } from 'framer-motion'
import { ImpossibleError } from 'kitchen/utils/error'
import * as Icons from '../../icons'
import { Point } from '../point'

export interface AnimatedSuccessIconProps {
  delay?: number
  size?: 24 | 64
}

export const AnimatedSuccessIcon = ({ delay, size = 64 }: AnimatedSuccessIconProps) => {
  switch (size) {
    case 64:
      return (
        <motion.div
          initial={{ opacity: 0, rotate: -30 }}
          animate={{ opacity: 1, rotate: 0 }}
          transition={{ duration: 0.3, delay }}
          style={{ width: 64, height: 64 }}
        >
          <Icons.S64.Success />
        </motion.div>
      )
    case 24:
      return (
        <Point
          as={motion.div}
          transition={{ type: 'tween', delay }}
          initial={{ rotate: -90 }}
          animate={{ rotate: 0 }}
        >
          <Icons.S16.Check />
        </Point>
      )
    default:
      throw new ImpossibleError('unhandled icon size', size)
  }
}
