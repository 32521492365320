import type { Company } from 'api/types/companies'
import { useLocale } from 'kitchen/hooks/use-locale'
import { alphabeticSort } from 'kitchen/utils/data'
import { matchSorter } from 'match-sorter'
import { useState, useDeferredValue, useMemo } from 'react'
import {
  Avatar,
  Item,
  NestedInteractive,
  SearchInput,
  ScrollArea,
  Sensitive,
} from 'salad/components'
import { Box, VStack, LineClamp } from 'salad/primitives'
import { theme } from 'salad/stitches'

interface CompanySelectProps {
  value: Company | null
  options: Company[]
  onValueChange: (company: Company) => void
}

export function CompanySelect({ value, options, onValueChange }: CompanySelectProps) {
  const locale = useLocale()
  const [isStuck, setStuck] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const deferredSearch = useDeferredValue(search.trim())

  const filtered = useMemo(
    () =>
      deferredSearch.length > 0
        ? matchSorter(options, deferredSearch, {
            keys: ['name', 'legalName', 'companyNumber'],
          })
        : options.sort((a, b) => alphabeticSort(a.name ?? '', b.name ?? '', locale)),
    [deferredSearch, locale, options]
  )

  return (
    <VStack css={{ gridTemplateRows: 'auto 1fr', minHeight: 0, overflow: 'hidden' }}>
      <Box
        py={16}
        px={24}
        data-is-stuck={isStuck || undefined}
        css={{
          '&[data-is-stuck]': {
            boxShadow: theme.shadows['elevation-bottom'],
          },
        }}
      >
        <SearchInput
          placeholder="Search by name"
          value={search}
          onValueChange={setSearch}
        />
      </Box>
      <ScrollArea
        variant="custom-scrollbar"
        onScroll={(event) => setStuck(event.currentTarget.scrollTop > 0)}
      >
        <VStack gap={2} px={16} pb={16}>
          {filtered.map((company) => (
            <NestedInteractive.Root key={company.id}>
              <Item.Root
                size="small"
                as="span"
                interactive
                css={{ paddingBlock: theme.space[8] }}
                role="option"
                aria-selected={value !== null && value.id === company.id}
              >
                <Item.Start>
                  <Avatar
                    size="small"
                    variant="square"
                    name={company.name}
                    uuid={company.id}
                  />
                </Item.Start>
                <NestedInteractive.Action onClick={() => onValueChange(company)}>
                  <Item.Content>
                    <Sensitive>
                      <LineClamp lines={2}>{company.name}</LineClamp>
                    </Sensitive>
                  </Item.Content>
                </NestedInteractive.Action>
              </Item.Root>
            </NestedInteractive.Root>
          ))}
        </VStack>
      </ScrollArea>
    </VStack>
  )
}
