import type { CurrencyCode } from 'domains/money/types'
import * as formats from 'kitchen/utils/formats'
import type { PaymentStats } from '../types'

// Will be removed after sanitization on the backend side is done:
// https://apron.atlassian.net/browse/PAY-650
export function isSanitizedPayeeReference(value: string, currency: CurrencyCode) {
  return value !== formats.payeeReference(value, currency)
}

export function checkHasPayments(paymentStats: PaymentStats) {
  return Object.values(paymentStats.paidPayrunsCountByFlowType).some((count) => count > 0)
}
