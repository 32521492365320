import { useCompanies } from 'api/hooks/companies'
import { useHasFeatureFlag } from 'api/hooks/features'
import { GiftPaymentsFreeTrialFlow } from 'domains/billing/components'
import {
  getPaymentsFreeTrialSubscriptionAddon,
  checkIsCompanyEligibleForPaymentsFreeTrial,
} from 'domains/billing/utils'
import { FormattedMessage } from 'domains/i18n/components'
import { useCompaniesPaymentStats } from 'domains/payment/queries'
import { useMemo } from 'react'
import { Dialog } from 'salad/components'
import * as Icons from 'salad/icons'
import { VStack, HStack, Text } from 'salad/primitives'
import { theme } from 'salad/stitches'

export function AppSidebarGiftPaymentsFreeTrial() {
  const companies = useCompanies({ status: ['ACTIVE', 'DRAFT'] }).data
  const practice = companies?.find((company) => company.type === 'PRACTICE')

  const [hasPaymentsFreeTrial] = useHasFeatureFlag(practice?.id, [
    'XMAS_PAYMENTS_FREE_TRIAL',
  ])

  const companiesWithoutAddon = useMemo(() => {
    if (hasPaymentsFreeTrial && practice && companies) {
      return companies.filter((company) => {
        if (company.id === practice.id || company.subscription === null) {
          return false
        }

        return !getPaymentsFreeTrialSubscriptionAddon(company.subscription)
      })
    }

    return []
  }, [companies, hasPaymentsFreeTrial, practice])

  const companiesPaymentStats = useCompaniesPaymentStats()
  const eligibleClients = useMemo(
    () =>
      companiesWithoutAddon
        .filter(
          (company) =>
            companiesPaymentStats.isSuccess &&
            company.id in companiesPaymentStats.data &&
            checkIsCompanyEligibleForPaymentsFreeTrial(
              company.subscription,
              undefined,
              companiesPaymentStats.data[company.id]
            )
        )
        .map((company) => company.id),
    [companiesPaymentStats.isSuccess, companiesPaymentStats.data, companiesWithoutAddon]
  )

  if (eligibleClients.length === 0 || practice === undefined) {
    return null
  }

  return (
    <Dialog.Root>
      <Dialog.Trigger style={{ cursor: 'pointer' }}>
        <HStack
          p={12}
          gap={8}
          css={{
            alignItems: 'center',
            svg: { color: theme.colors['dark-blue'] },
            borderRadius: theme.radii[8],
            backgroundColor: theme.colors['light-blue-20'],
          }}
        >
          <Icons.S24.Present />
          <VStack>
            <Text variant="title-14" color="black" align="start">
              <FormattedMessage
                id="billing.gift-free-payments"
                defaultMessage="Gift free payments"
              />
            </Text>
            <Text variant="paragraph-12" color="grey-60" align="start">
              <FormattedMessage
                id="billing.limited-time-offer"
                defaultMessage="Limited time offer"
              />
            </Text>
          </VStack>
        </HStack>
      </Dialog.Trigger>
      <Dialog.Content variant={512}>
        {(dialog) => (
          <GiftPaymentsFreeTrialFlow
            practice={practice}
            clients={eligibleClients}
            onExit={() => dialog.setOpen(false)}
          />
        )}
      </Dialog.Content>
    </Dialog.Root>
  )
}
