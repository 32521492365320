import type { Company, CompanyId } from 'api/types/companies'
import { allocate, multiply } from 'dinero.js'
import type { Money } from 'domains/money/types'
import { toDinero, toMoney } from 'domains/money/utils'
import type { PaymentStats } from 'domains/payment/types'
import { checkHasPayments } from 'domains/payment/utils'
import { getFlowTypeLabel } from 'domains/payrun/utils'
import { Timestamp, Locale, StorageKey } from 'kitchen/constants'
import { ImpossibleError } from 'kitchen/utils/error'
import { pluralize } from 'kitchen/utils/formats'
import { unique, toTypedArray } from 'kitchen/utils/helpers'
import {
  diffDays,
  toDate,
  getUTCTime,
  getNextMonth,
  startOfMonth,
} from 'kitchen/utils/native-date'
import { match, P } from 'ts-pattern'
import type {
  PricingPlanPeriod,
  PricingPlanLimitType,
  Subscription,
  CompanySubscription,
  ActiveSubscription,
  SubscriptionAddon,
  ActiveSubscriptionAddon,
  EndedSubscriptionAddon,
} from './types'

export const getPayerType = (
  practiceId: CompanyId | undefined,
  subscription: Subscription | null
) => {
  if (subscription === null) {
    return 'NONE'
  }

  if (subscription.payerId === practiceId) {
    return 'PRACTICE'
  }

  if (subscription.payerId === subscription.subscriberId) {
    return 'COMPANY'
  }

  if (subscription.payerId !== subscription.subscriberId) {
    return 'OTHER'
  }

  throw new Error('Impossible subscription payer state')
}

export const getPayerName = (
  practiceId: CompanyId | undefined,
  subscription: Subscription | null
) => {
  const payerType = getPayerType(practiceId, subscription)
  switch (payerType) {
    case 'COMPANY':
      return 'Client'
    case 'PRACTICE':
      return 'Practice'
    case 'OTHER':
      return 'Other practice'
    case 'NONE':
      return '–'
    default:
      throw new ImpossibleError('Unhandled payer type', payerType)
  }
}

export function getPeriodLabel(input: PricingPlanPeriod) {
  switch (input) {
    case '1M':
      return 'month'
    case '1Y':
      return 'year'
    default:
      throw new ImpossibleError('Unhandled period', input)
  }
}

export function getBillingCycleLabel(input: PricingPlanPeriod) {
  switch (input) {
    case '1M':
      return 'Monthly'
    case '1Y':
      return 'Yearly'
    default:
      throw new ImpossibleError('Unhandled period', input)
  }
}

export function getPricingPlanUsageLabel(type: PricingPlanLimitType) {
  switch (type) {
    case 'CAPTURE':
      return 'Captured documents'
    default:
      return `${getFlowTypeLabel(type)} payments`
  }
}

interface PricingPlanOverageMessageOptions {
  type: PricingPlanLimitType
  overage: number
  fee: string
}

export function getPricingPlanOverageMessage({
  type,
  fee,
  overage,
}: PricingPlanOverageMessageOptions) {
  switch (type) {
    case 'CAPTURE':
      return `${fee} overage fee for ${pluralize(
        overage,
        'additional document',
        'additional documents'
      )}.`
    default:
      const label = getFlowTypeLabel(type).toLowerCase()
      return `${fee} overage fee for ${overage} additional ${label}.`
  }
}

export const getRemainingPrice = (input: Money, remaining: number, days: number) =>
  toMoney(allocate(toDinero(input), [remaining, days - remaining])[0])

export const getOverage = (input: Money, count: number) =>
  toMoney(multiply(toDinero(input), count))

export const isActiveSubscription = (input: Subscription) => input.state === 'ACTIVE'

export const isActiveNotTrialSubscription = (input: CompanySubscription) =>
  isActiveSubscription(input) && input.pricingPlan.name !== 'Trial'

export function getSortedSubscriptions<Item extends Subscription>(input: Item[]) {
  return input
    .slice()
    .sort((a, b) =>
      a.startDate === null || b.startDate === null
        ? 0
        : a.startDate.localeCompare(b.startDate, undefined, { numeric: true })
    )
}

export const getSortedActiveSubscriptions = (list: Subscription[]) =>
  getSortedSubscriptions(list.filter(isActiveSubscription))

export const getSubscribers = (input: Subscription[]) =>
  unique(input.map((item) => item.subscriberId))

export const getCompanySubscriptions = (
  companyId: CompanyId,
  subscriptions: Subscription[]
) => subscriptions.filter((item) => item.subscriberId === companyId)

export const getSortedCompanySubscriptions = (
  companyId: CompanyId,
  subscriptions: Subscription[]
) =>
  getSortedSubscriptions(subscriptions.filter((item) => item.subscriberId === companyId))

export function getCompanyActiveAndNextSubscriptions(
  companyId: CompanyId,
  subscriptions: Subscription[]
): [current: ActiveSubscription | undefined, next: ActiveSubscription | undefined] {
  const result = getSortedActiveSubscriptions(
    getCompanySubscriptions(companyId, subscriptions)
  )

  return [result[0], result[1]]
}

export const getCompanyExpiredSubscription = (
  companyId: CompanyId,
  subscriptions: Subscription[]
) =>
  getSortedCompanySubscriptions(companyId, subscriptions).findLast(isSubscriptionExpired)

export const getCompanyActiveSubscription = (
  companyId: CompanyId,
  subscriptions: Subscription[]
) => getCompanyActiveAndNextSubscriptions(companyId, subscriptions)[0]

export function getSubscribersSubtitle(
  company: Company,
  subscribers: CompanyId[],
  subscriptions: Subscription[]
) {
  if (subscribers.length === 1 && subscriptions.length > 0) {
    return subscriptions[0].subscriberName
  }

  switch (company.type) {
    case 'PRACTICE':
      return pluralize(subscribers.length, 'client', 'clients')
    case 'BUSINESS':
    case 'UNDEFINED':
      if (subscriptions.length === 0) {
        return company.name
      }

      return pluralize(subscribers.length, 'company', 'companies')
  }
}

export const isSubscriptionExpired = (input: Subscription) =>
  input.state === 'TERMINATED' ||
  (Boolean(input.expirationDate) &&
    Timestamp.NOW >= getUTCTime(toDate(input.expirationDate)))

export const checkHasActiveSubscription = (
  input: Subscription | null
): input is Subscription => input !== null && !isSubscriptionExpired(input)

export function isSubscriptionsCancellable(
  active: CompanySubscription | undefined,
  next: CompanySubscription | undefined
) {
  return (
    (active !== undefined &&
      active.pricingPlan.name !== 'Trial' &&
      active.pricingPlan.name !== 'Free' &&
      active.expirationDate === undefined) ||
    next !== undefined
  )
}

export function getExpirationDate(subscription: CompanySubscription | null = null) {
  if (subscription === null) {
    return startOfMonth(Locale.TODAY)
  }

  if (isSubscriptionExpired(subscription)) {
    return Locale.TODAY
  }

  if (subscription.expirationDate && subscription.pricingPlan.name === 'Trial') {
    return toDate(subscription.expirationDate)
  }

  return getNextMonth(Locale.TODAY)
}

const SELECTED_CLIENTS_SEARCH_PARAM = 'clients'
const SELECTED_CLIENTS_SEPARATOR = '|'

type SelectedClientsSearchParamsKeyType = 'callback' | 'return'

function getSelectedClientsSearchParamsKey(type: SelectedClientsSearchParamsKeyType) {
  return `clients-${type}`
}

function getSelectedClientsStorageKey(type: SelectedClientsSearchParamsKeyType) {
  const url = new URL(window.location.toString())
  const storageValue = url.searchParams.get(getSelectedClientsSearchParamsKey(type))

  if (storageValue === null) {
    const key = `${StorageKey.SELECTED_CLIENTS}/${Date.now()}`

    url.searchParams.set(getSelectedClientsSearchParamsKey(type), key)
    window.history.replaceState(window.history.state, '', url)

    return key
  }

  return storageValue
}

export function getSelectedClientsStorageSearchParams(
  type: SelectedClientsSearchParamsKeyType
) {
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.set(SELECTED_CLIENTS_SEARCH_PARAM, getSelectedClientsStorageKey(type))

  return searchParams
}

export function getSelectedClientsFromStorage(type: SelectedClientsSearchParamsKeyType) {
  const value = sessionStorage.getItem(getSelectedClientsStorageKey(type))

  if (value === null) {
    return []
  }

  return toTypedArray<CompanyId>(value.split(SELECTED_CLIENTS_SEPARATOR))
}

export function updateSelectedClientsInStorage(
  type: SelectedClientsSearchParamsKeyType,
  clients: CompanyId[]
) {
  sessionStorage.setItem(
    getSelectedClientsStorageKey(type),
    clients.join(SELECTED_CLIENTS_SEPARATOR)
  )
}

export function checkIsLineItemsShown(invoiceDate: string) {
  const showFromDate = ['staging', 'development'].includes(process.env.BUILD_MODE)
    ? '2024-07-30'
    : '2024-08-31'

  return new Date(invoiceDate) > new Date(showFromDate)
}

export const checkIsPracticePayer = (company: Company, practice: Company | undefined) =>
  match(getPayerType(practice?.id, company.subscription))
    .with(P.union('PRACTICE', 'OTHER'), () => true)
    .with(P.union('COMPANY', 'NONE'), () => false)
    .exhaustive()

export const getSortedSubscriptionAddons = (addons: SubscriptionAddon[]) =>
  addons
    .slice()
    .sort((a, b) =>
      a.startDate === null || b.startDate === null
        ? 0
        : a.startDate.localeCompare(b.startDate, undefined, { numeric: true })
    )

export const getPaymentsFreeTrialSubscriptionAddon = (
  subscription: CompanySubscription
) =>
  getSortedSubscriptionAddons(subscription.addons).find(
    (addon): addon is ActiveSubscriptionAddon | EndedSubscriptionAddon =>
      addon.product === 'SUPPLIER_UNLIMITED' &&
      (addon.state === 'ACTIVE' || addon.state === 'ENDED')
  )

export function checkIsSubscriptionEligibleForPaymentsFreeTrial(
  subscription: CompanySubscription | null
): subscription is ActiveSubscription {
  if (subscription === null) {
    return false
  }

  switch (subscription.state) {
    case 'ACTIVE':
      switch (subscription.pricingPlan.name) {
        case 'Go':
        case 'Starter':
        case 'Pitch the switch':
          return true
        default:
          return false
      }
    case 'TERMINATED':
      return false
    default:
      throw new ImpossibleError('Unhandled subscription', subscription)
  }
}

export function checkIsCompanyEligibleForPaymentsFreeTrial(
  subscription: CompanySubscription | null,
  practice: Company | undefined,
  paymentStats: PaymentStats
): subscription is ActiveSubscription {
  return (
    // Business owners (not Accountants/Bookkeepers)
    practice === undefined &&
    // Completed intro trial & starter plan user
    checkIsSubscriptionEligibleForPaymentsFreeTrial(subscription) &&
    // Company account has not settled payrun
    !checkHasPayments(paymentStats)
  )
}

export function getSubscriptionAddonDays(addon: SubscriptionAddon) {
  const startDate = toDate(addon.startDate)
  const endDate = toDate(addon.endDate)

  return diffDays(endDate, startDate)
}

export const checkHasPaymentsFreeTrialAddon = (
  subscription: CompanySubscription | null
) =>
  subscription !== null &&
  getPaymentsFreeTrialSubscriptionAddon(subscription) !== undefined
