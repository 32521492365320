import { useTrackPageVisit } from 'domains/analytics/hooks'
import { FetchProvider } from 'kitchen/context/fetch'
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  createBrowserRouter,
  RouterProvider,
  useRouteError,
} from 'kitchen/router'
import React, { Suspense } from 'react'
import { LoadingStatus } from './components/loading-status'
import { Path, Match } from './constants'
import { CommentAttachmentReview } from './features/comment-attachment-review'
import { RequireAuth, RequireKYC } from './features/protected'
import { MaybeRequire2Fa } from './features/protected/maybe-require-2fa'

const CompanyPage = React.lazy(() =>
  import('./features/companies/company-page').then((mod) => ({
    default: mod.CompanyPage,
  }))
)

const PaymentLinkPage = React.lazy(() =>
  import('./features/payment-link/payment-link-page').then((mod) => ({
    default: mod.PaymentLinkPage,
  }))
)

const InvoiceSourcePage = React.lazy(() =>
  import('./features/invoice-source-page').then((mod) => ({
    default: mod.InvoiceSourcePage,
  }))
)

const CardChallengeStatusPage = React.lazy(() =>
  import('./features/card-challenge/card-challenge-status-page').then((mod) => ({
    default: mod.CardChallengeStatusPage,
  }))
)

const HubRoutes = React.lazy(() =>
  import('./features/hub/hub-routes').then((mod) => ({
    default: mod.HubRoutes,
  }))
)

const UserOnboardingPage = React.lazy(() =>
  import('./features/user-onboarding/user-onboarding-page').then((mod) => ({
    default: mod.UserOnboardingPage,
  }))
)

const ForceSetup2Fa = React.lazy(() =>
  import('./features/auth/force-setup-2fa').then((mod) => ({
    default: mod.ForceSetup2Fa,
  }))
)

const PracticeInitialOnboardingPage = React.lazy(() =>
  import('./features/practice-initial-onboarding/practice-initial-onboarding-page').then(
    (mod) => ({
      default: mod.PracticeInitialOnboardingPage,
    })
  )
)

const AuthRoutes = React.lazy(() =>
  import('./features/auth/auth-routes').then((mod) => ({
    default: mod.AuthRoutes,
  }))
)

const UserSettingsRoutes = React.lazy(() =>
  import('./features/user-settings/user-settings-routes').then((mod) => ({
    default: mod.UserSettingsRoutes,
  }))
)

const SetupPasswordPageRoute = React.lazy(() =>
  import('./features/auth/setup-password-page').then((mod) => ({
    default: mod.SetupPasswordPage,
  }))
)

const PermissionsRequestPage = React.lazy(() =>
  import('./features/permissions-request/permissions-request-page').then((mod) => ({
    default: mod.PermissionsRequestPage,
  }))
)

const Root = () => {
  const location = useLocation()
  useTrackPageVisit(location.pathname + location.search)

  return (
    <Routes>
      <Route
        index
        element={
          <RequireAuth>
            <Navigate to={Path.COMPANIES_HUB} replace />
          </RequireAuth>
        }
      />
      <Route
        path={Match.AUTH}
        element={
          <FetchProvider>
            <AuthRoutes />
          </FetchProvider>
        }
      />
      <Route
        path={Match.AUTH_SETUP_PASSWORD}
        element={
          <RequireAuth>
            <SetupPasswordPageRoute />
          </RequireAuth>
        }
      />
      <Route
        path={Match.USER_ONBOARDING}
        element={
          <RequireAuth>
            <MaybeRequire2Fa>
              <UserOnboardingPage />
            </MaybeRequire2Fa>
          </RequireAuth>
        }
      />
      <Route
        path={Match.AUTH_SETUP_2FA}
        element={
          <RequireAuth>
            <ForceSetup2Fa />
          </RequireAuth>
        }
      />
      <Route
        path={Match.USER_SETTINGS}
        element={
          <RequireAuth>
            <MaybeRequire2Fa>
              <UserSettingsRoutes />
            </MaybeRequire2Fa>
          </RequireAuth>
        }
      />
      <Route
        path={Match.PRACTICE_ONBOARDING}
        element={
          <RequireAuth>
            <MaybeRequire2Fa>
              <PracticeInitialOnboardingPage />
            </MaybeRequire2Fa>
          </RequireAuth>
        }
      />

      <Route
        // fallback for old routes
        path={Match.DASHBOARD}
        element={<Navigate to={Path.COMPANIES_HUB} />}
      />
      <Route path={Match.HUB} element={<Navigate to={Path.COMPANIES_HUB} />} />
      <Route
        path={Match.COMPANIES}
        element={
          <Routes>
            <Route index element={<Navigate to={Path.COMPANIES_HUB} />} />
            <Route
              path={Match.COMPANY_ID}
              element={
                <RequireAuth>
                  <MaybeRequire2Fa>
                    <RequireKYC>
                      <Suspense
                        fallback={
                          <LoadingStatus layout="hub-page" practice={undefined} />
                        }
                      >
                        <CompanyPage />
                      </Suspense>
                    </RequireKYC>
                  </MaybeRequire2Fa>
                </RequireAuth>
              }
            />
            <Route
              // fallback for old routes
              path={Match.DASHBOARD}
              element={<Navigate to={Path.COMPANIES_HUB} />}
            />
            <Route
              path={Match.HUB}
              element={
                <RequireAuth>
                  <MaybeRequire2Fa>
                    <RequireKYC>
                      <Suspense
                        fallback={
                          <LoadingStatus layout="hub-page" practice={undefined} />
                        }
                      >
                        <HubRoutes />
                      </Suspense>
                    </RequireKYC>
                  </MaybeRequire2Fa>
                </RequireAuth>
              }
            />
          </Routes>
        }
      />
      <Route
        path={Match.COMMENT_ATTACHMENT}
        element={
          <RequireAuth>
            <MaybeRequire2Fa>
              <CommentAttachmentReview />
            </MaybeRequire2Fa>
          </RequireAuth>
        }
      />
      <Route
        path={Match.INVOICE_SOURCE}
        element={
          <RequireAuth>
            <MaybeRequire2Fa>
              <InvoiceSourcePage />
            </MaybeRequire2Fa>
          </RequireAuth>
        }
      />
      <Route
        path={Match.PAYMENT_LINK}
        element={
          <FetchProvider>
            <PaymentLinkPage />
          </FetchProvider>
        }
      />
      <Route
        path={Match.PERMISSIONS_REQUEST_APPROVE}
        element={
          <FetchProvider>
            <PermissionsRequestPage />
          </FetchProvider>
        }
      />
      <Route path={Match.CARD_CHALLENGE} element={<CardChallengeStatusPage />} />
      <Route path={Match.ALL} element={<Navigate to={Path.INDEX} />} />
    </Routes>
  )
}

const router = createBrowserRouter([
  {
    path: '*',
    element: <Root />,
    ErrorBoundary: () => {
      throw useRouteError()
    },
  },
])

export const AppRoutes = () => {
  return <RouterProvider router={router} />
}
