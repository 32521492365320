import { useVisibleCompanies } from 'api/hooks/companies'
import type { Company } from 'api/types/companies'
import { Avatar, Item, Popover, Sensitive, Surface } from 'salad/components'
import * as Icons from 'salad/icons'
import { LineClamp } from 'salad/primitives'
import { CompanySelect } from '../company-select'

interface CompanySwitcherProps {
  variant: 'sidebar' | 'header'
  value: Company
  onValueChange: (company: Company) => void
}

export function CompanySwitcher({ variant, value, onValueChange }: CompanySwitcherProps) {
  const companies = useVisibleCompanies().data ?? []
  const isDisabled = companies.length <= 1

  if (isDisabled) {
    return (
      <Item.Root
        as="span"
        variant={variant === 'sidebar' ? 'switcher' : 'default'}
        size={variant === 'sidebar' ? 'large' : 'small'}
        hug={variant === 'header'}
        style={{ justifySelf: variant === 'header' ? 'center' : 'normal' }}
      >
        <Item.Start>
          <Avatar variant="square" size="small" uuid={value.id} name={value.name} />
        </Item.Start>
        <Item.Content>
          <Sensitive>
            <LineClamp lines={2}>{value.name}</LineClamp>
          </Sensitive>
        </Item.Content>
      </Item.Root>
    )
  }

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        {(trigger) => (
          <Item.Root
            variant={variant === 'sidebar' ? 'switcher' : 'default'}
            size={variant === 'sidebar' ? 'large' : 'small'}
            aria-pressed={trigger.isOpen}
            hug={variant === 'header'}
            style={{ justifySelf: variant === 'header' ? 'center' : 'normal' }}
          >
            <Item.Start>
              <Avatar variant="square" size="small" uuid={value.id} name={value.name} />
            </Item.Start>
            <Item.Content>
              <Sensitive>
                <LineClamp lines={2}>{value.name}</LineClamp>
              </Sensitive>
            </Item.Content>
            <Item.End color="black">
              <Icons.S16.Dropdown variant={trigger.isOpen ? 'pressed' : 'default'} />
            </Item.End>
          </Item.Root>
        )}
      </Popover.Trigger>
      <Popover.Content size={420} align={variant === 'sidebar' ? 'start' : 'center'}>
        {(popover) => (
          <Surface
            variant="popover"
            css={{
              display: 'grid',
              gridTemplate: '1fr / 1fr',
              maxHeight: `min(400px, calc(${Popover.availableContentHeight} - 16px))`,
            }}
          >
            <CompanySelect
              options={companies}
              value={value}
              onValueChange={(company) => {
                popover.setOpen(false)
                onValueChange(company)
              }}
            />
          </Surface>
        )}
      </Popover.Content>
    </Popover.Root>
  )
}
