export const Alert = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64ZM35 36V16H29V36H35ZM35 48V41H29V48H35Z"
      fill="currentColor"
    />
  </svg>
)
