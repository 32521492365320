import { lazy, Suspense } from 'react'

import { Box } from 'salad/primitives'
import type { ConfettiBaseProps } from './confetti-base'

const ConfettiBase = lazy(() =>
  import('./confetti-base').then((mod) => ({ default: mod.ConfettiBase }))
)

interface ConfettiProps extends ConfettiBaseProps {
  size: number
}

export const Confetti = ({ size, variant, children }: ConfettiProps) => (
  <Suspense fallback={<Box style={{ height: size, width: size }} />}>
    <ConfettiBase variant={variant}>{children}</ConfettiBase>
  </Suspense>
)
