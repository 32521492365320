import { forwardRef } from 'react'
import { match } from 'ts-pattern'
import { ButtonBase } from '../../primitives'
import { styled, theme } from '../../stitches'
import { Spinner } from '../spinner'

type VideoButtonSize = 96 | 48

function getIconSize(size: VideoButtonSize) {
  switch (size) {
    case 96:
      return 48
    case 48:
      return 24
  }
}

const VideoButtonBase = styled(ButtonBase, {
  display: 'grid',
  placeItems: 'center',
  backgroundColor: theme.colors['black-alpha-20'],
  color: theme.colors['white'],
  borderRadius: theme.radii['round'],
  backdropFilter: 'blur(10px)',
  size: 96,
  transition: 'opacity 0.3s',
  variants: {
    playing: {
      true: { opacity: 0, '*:hover > &, &:focus-visible': { opacity: 1 } },
      false: { opacity: 1 },
    },
    loading: {
      true: { opacity: 1 },
    },
    size: {
      96: { size: 96 },
      48: { size: 48 },
    },
  },
  defaultVariants: {
    playing: false,
    loading: false,
  },
})

export interface VideoButtonProps
  extends React.ComponentPropsWithoutRef<typeof VideoButtonBase> {
  size: VideoButtonSize
  playing?: boolean
  loading?: boolean
}

export const VideoButton = forwardRef<HTMLButtonElement, VideoButtonProps>(
  function VideoButton(
    { size, loading = false, playing = false, ...rest },
    forwardedRef
  ) {
    const iconSize = getIconSize(size)
    return (
      <VideoButtonBase
        ref={forwardedRef}
        size={size}
        playing={playing}
        loading={loading}
        disabled={loading}
        {...rest}
      >
        {match({ loading, playing })
          .with({ loading: true }, () => <Spinner size={iconSize} />)
          .with({ playing: true }, () => (
            <svg width={iconSize} height={iconSize} viewBox="0 0 32 32">
              <path
                d="M18.6665 5.33333C18.6665 4.59695 19.2635 4 19.9998 4H23.9998C24.7362 4 25.3332 4.59695 25.3332 5.33333V26.6667C25.3332 27.403 24.7362 28 23.9998 28H19.9998C19.2635 28 18.6665 27.403 18.6665 26.6667V5.33333Z"
                fill="currentColor"
              />
              <path
                d="M6.6665 5.33333C6.6665 4.59695 7.26346 4 7.99984 4H11.9998C12.7362 4 13.3332 4.59695 13.3332 5.33333V26.6667C13.3332 27.403 12.7362 28 11.9998 28H7.99984C7.26346 28 6.6665 27.403 6.6665 26.6667V5.33333Z"
                fill="currentColor"
              />
            </svg>
          ))
          .with({ playing: false }, () => (
            <svg width={iconSize} height={iconSize} viewBox="0 0 32 32">
              <path
                d="M27.882 14.7769C28.824 15.3203 28.824 16.6797 27.882 17.2231L9.52901 27.809C8.58772 28.352 7.41162 27.6726 7.41162 26.586L7.41162 5.41403C7.41162 4.32739 8.58772 3.96804 9.529 4.19097L27.882 14.7769Z"
                fill="currentColor"
              />
            </svg>
          ))
          .exhaustive()}
      </VideoButtonBase>
    )
  }
)
