import { createCancellablePromise } from 'kitchen/utils/async'
import { useEffect, useState } from 'react'
import { fetchLocaleMessages } from '../../requests'
import type { AppLocale, IntlMessagesResponse } from '../../types'

interface MessageLoaderPropsBase {
  children: (messages: IntlMessagesResponse) => React.ReactNode
}

interface AppMessageLoaderProps extends MessageLoaderPropsBase {
  locale: AppLocale
  packageName: 'app'
}

export type MessageLoaderProps = AppMessageLoaderProps

export const IntlMessageLoader = ({
  locale,
  packageName,
  children,
}: MessageLoaderProps) => {
  const [messages, setMessages] = useState<IntlMessagesResponse | null>(null)
  useEffect(() => {
    // for some reason fetching via react-query fails Playwright on CI.
    // experiments: https://github.com/Hello-Cash-Ltd/hellocash-app-frontend/pull/3512
    const promise = createCancellablePromise(async () =>
      setMessages(await fetchLocaleMessages({ locale, packageName }))
    )

    return () => {
      promise.abort('Cancelled')
    }
  }, [locale, packageName])

  if (messages === null) {
    return null
  }

  return children(messages)
}
