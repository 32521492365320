import { useUpdateCompanySettings } from 'api/hooks/companies'
import type { Company } from 'api/types/companies'
import type { CompanyId } from 'domains/company/types'
import { FormattedMessage } from 'domains/i18n/components'
import { ImpossibleError } from 'kitchen/utils/error'
import { ensure } from 'kitchen/utils/helpers'
import { useState } from 'react'
import { Toast } from 'salad/components'
import { useSendXmasAddonToClients } from '../../queries'
import { GiftPaymentsFreeTrialConfirmation } from './gift-payments-free-trial-confirmation'
import { GiftPaymentsFreeTrialOverview } from './gift-payments-free-trial-overview'

type GiftPaymentsFreeTrialFlowType = { step: 'overview' } | { step: 'confirmation' }

interface GiftPaymentsFreeTrialFlowProps {
  practice: Company
  clients: CompanyId[]
  onExit: () => void
}

export function GiftPaymentsFreeTrialFlow({
  practice,
  clients,
  onExit,
}: GiftPaymentsFreeTrialFlowProps) {
  const toast = Toast.useContext()
  const [flow, setFlow] = useState<GiftPaymentsFreeTrialFlowType>({ step: 'overview' })

  const updateCompanySettings = useUpdateCompanySettings()
  const sendXmasAddonToClients = useSendXmasAddonToClients({
    onError: () =>
      toast.show(
        <Toast.Root variant="error">
          <Toast.Title>
            <FormattedMessage
              id="billing.payments-free-trial-flow.errors.start-trial"
              defaultMessage="Failed to start trial"
            />
          </Toast.Title>
        </Toast.Root>
      ),
  })

  switch (flow.step) {
    case 'overview':
      return (
        <GiftPaymentsFreeTrialOverview
          practice={practice}
          clients={clients}
          onConfirm={async () => {
            await sendXmasAddonToClients.mutateAsync({
              practiceId: practice.id,
              subscriptionId: ensure(practice.subscription).id,
            })

            setFlow({ step: 'confirmation' })

            updateCompanySettings.mutate({
              companyId: practice.id,
              xmasGift2024Sent: true,
            })
          }}
          confirming={sendXmasAddonToClients.isLoading}
        />
      )
    case 'confirmation':
      return <GiftPaymentsFreeTrialConfirmation onDone={() => onExit()} />
    default:
      throw new ImpossibleError('Unhandled gift payments free trial flow step', flow)
  }
}
