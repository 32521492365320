import type { LoadOptions } from '@rudderstack/analytics-js'
import { RudderAnalytics } from '@rudderstack/analytics-js'

const rudderAnalytics = new RudderAnalytics()

/**
 * if URL doesn't contain the id, Rudderstack will generate one and set to storage.
 */
function populateAnonymousId() {
  if (typeof window === 'undefined') {
    return
  }
  const searchParams = new URLSearchParams(window.location.search)
  const rudderIdFromQuery = searchParams.get('rudderId')
  const linkedInIdFromQuery = searchParams.get('li_fat_id')

  /**
   * present rudderId in URL means we've already generated it earlier.
   * (probably on another domain)
   */
  if (rudderIdFromQuery) {
    return rudderAnalytics.setAnonymousId(rudderIdFromQuery)
  }
  /**
   * if linkedin click ID is present, take it instead of generating a random one.
   */
  if (linkedInIdFromQuery) {
    return rudderAnalytics.setAnonymousId(linkedInIdFromQuery)
  }
}

function initRudderAnalytics(loadOptions?: Partial<LoadOptions>) {
  rudderAnalytics.load(
    process.env.RUDDERSTACK_PUBLIC_WRITE_KEY,
    'https://sayhelloyfposy.dataplane.rudderstack.com',
    {
      storage: { type: 'localStorage' },
      plugins: ['XhrQueue', 'StorageEncryption'],
      ...loadOptions,
    }
  )
  populateAnonymousId()
}

export { rudderAnalytics, initRudderAnalytics }
