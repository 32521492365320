import { useCompanySettings } from 'api/hooks/companies'
import type { CompanyId, Company } from 'api/types/companies'
import { pluralize } from 'kitchen/utils/formats'
import { cdn, assert } from 'kitchen/utils/helpers'
import { Surface, Status } from 'salad/components'
import { Image } from 'salad/primitives'

interface GiftPaymentsFreeTrialOverviewProps {
  practice: Company
  clients: CompanyId[]
  onConfirm: () => void
  confirming: boolean
}

export function GiftPaymentsFreeTrialOverview({
  practice,
  clients,
  onConfirm,
  confirming,
}: GiftPaymentsFreeTrialOverviewProps) {
  const companySettings = useCompanySettings(
    { companyId: practice.id },
    { suspense: true }
  )

  assert(companySettings.data)

  return (
    <Surface p={32} style={{ maxWidth: 448, marginInline: 'auto' }}>
      <Status.Root variant="dialog">
        <Status.Icon>
          <Image
            src={cdn('/illustrations/paper-plane.png')}
            srcSet={cdn('/illustrations/paper-plane@2x.png 2x')}
            width={360}
            height={240}
            alt=""
          />
        </Status.Icon>
        <Status.Title>Gift unlimited payments for&nbsp;60&nbsp;days 🎄</Status.Title>
        <Status.Description>
          Any client who hasn't tried payments yet is eligible. We'll show them what Apron
          has to offer, and they'll have until 31st December to start their trial.
        </Status.Description>
        {companySettings.data.xmasGift2024Sent ? (
          <Status.Action variant="main" disabled>
            Gift sent
          </Status.Action>
        ) : (
          <Status.Action variant="main" onClick={() => onConfirm()} loading={confirming}>
            Send gift to {pluralize(clients.length, 'client', 'clients')}
          </Status.Action>
        )}
      </Status.Root>
    </Surface>
  )
}
