import { ImpossibleError } from 'kitchen/utils/error'
import type { IntlMessagesPayload, IntlMessagesResponse } from './types'

export const fetchLocaleMessages = async (
  payload: IntlMessagesPayload
): Promise<IntlMessagesResponse> => {
  switch (payload.packageName) {
    case 'app': {
      const messagesModule = await import(`./messages/app/${payload.locale}.json`)
      return messagesModule.default
    }
    case 'website': {
      const messagesModule = await import(`./messages/website/${payload.locale}.json`)
      return messagesModule.default
    }
    default:
      throw new ImpossibleError('unhandled package', payload.packageName)
  }
}
