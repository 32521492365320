import type { Account, AccountType } from 'api/types/accounts'
import type { Beneficiary } from 'api/types/beneficiaries'
import type { PaymentFees } from 'api/types/fees'
import type { CurrencyCode, Money } from 'domains/money/types'
import { getTotal, getFee } from 'domains/money/utils'
import { ACCOUNT_FEES, INTERNATIONAL_TRANSFER_FEES } from 'kitchen/constants'
import { unique, nonNullable } from './helpers'

/** From: https://github.com/Hello-Cash-Ltd/hc-server/blob/b94d76a4090418ec121b7f875375679ab9dddeaf/hcs-mono/adapter/src/main/kotlin/com/hc/hcsmono/fee/impl/FeeCalculator.kt#L30 */
const isBeneficiaryWithFee = (
  input: Beneficiary | null | undefined
): input is Beneficiary =>
  !(
    !input ||
    (input.currency === 'EUR' && input.paymentType === 'LOCAL') ||
    (input.currency === 'GBP' && input.paymentType === 'LOCAL')
  )

export function getBeneficiariesWithFee(
  beneficiaries: Array<Beneficiary | undefined>,
  grouped: boolean
) {
  const ids = beneficiaries.filter(isBeneficiaryWithFee).map((item) => item.id)
  return grouped ? unique(ids) : ids
}

function getInternationalTransferFee(
  beneficiaries: Array<Beneficiary | undefined>,
  currency: CurrencyCode,
  grouped: boolean
) {
  const ids = getBeneficiariesWithFee(beneficiaries, grouped)

  return getTotal(
    ids.map(() => INTERNATIONAL_TRANSFER_FEES[currency]).filter(nonNullable),
    currency
  )
}

const getProcessingFee = (total: Money, accountType: AccountType) =>
  getFee(total, ACCOUNT_FEES[accountType])

export const getPaymentFees = (
  total: Money,
  accountType: AccountType,
  beneficiaries: Array<Beneficiary | undefined>,
  grouped: boolean
): PaymentFees => {
  const internationalTransferFee = getInternationalTransferFee(
    beneficiaries,
    total.currency,
    grouped
  )

  const totalWithInternationalTransferFee = getTotal(
    [total, internationalTransferFee],
    total.currency
  )

  const processingFee = getProcessingFee(totalWithInternationalTransferFee, accountType)
  const totalFee = getTotal([processingFee, internationalTransferFee], total.currency)

  return {
    internationalTransfer: internationalTransferFee,
    processing: processingFee,
    total: totalFee,
  }
}

export const applyPaymentFees =
  (
    account: Account | null,
    beneficiaries: Array<Beneficiary | undefined>,
    grouped: boolean
  ) =>
  (total: Money): Money => {
    if (account) {
      const paymentFees = getPaymentFees(
        total,
        account.accountType,
        beneficiaries,
        grouped
      )
      return getTotal([total, paymentFees.total], total.currency)
    }

    return total
  }
