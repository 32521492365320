import { noop } from './helpers'

/**
 *
 * @param ms
 * usage: await sleep(200); // then do stuff
 */
export function sleep(ms: number) {
  return new Promise<void>((resolve) => setTimeout(resolve, ms))
}

export const settled = <R>(promise: Promise<R>) =>
  Promise.allSettled([promise]).then(([result]) => result)

export const sequence = <R>(input: Array<() => Promise<R>>) =>
  input.reduce<Promise<R[]>>(
    (acc, fn) => acc.then((result) => Promise.all([...result, fn()])),
    Promise.all([])
  )

export async function until<R>(
  fun: () => Promise<R>,
  check: (result: R) => boolean,
  delay: number
): Promise<R> {
  const result = await fun()

  if (check(result)) {
    return result
  }

  await sleep(delay)
  return until(fun, check, delay)
}

export type OnSettledProgress<T> = (
  /** index in the input promise array */
  index: number,
  result: PromiseSettledResult<T>
) => void

interface BatchSettledProgressParams<I, R> {
  items: I[]
  callback: (item: I) => Promise<R>
  onSettle?: OnSettledProgress<R>
  batchSize: number
}

export async function batchSettledProgress<I, R>({
  items,
  callback,
  onSettle = noop,
  batchSize,
}: BatchSettledProgressParams<I, R>): Promise<PromiseSettledResult<R>[]> {
  const results: PromiseSettledResult<R>[] = []
  for (let index = 0; index < items.length; index += batchSize) {
    const batch = items.slice(index, index + batchSize)
    const batchPromises = batch.map(callback)

    await Promise.all(
      batchPromises.map((promise, batchItemIndex) =>
        settled(promise).then((result) => {
          results.push(result)
          onSettle(batchItemIndex + index, result)
          return result
        })
      )
    )
  }

  return results
}

export function settledProgress<T>(
  promises: Promise<T>[],
  onSettle: OnSettledProgress<T> = () => {
    return
  }
) {
  const results: PromiseSettledResult<T>[] = []
  return Promise.all(
    promises.map((promise, index) =>
      settled(promise).then((result) => {
        results.push(result)
        onSettle(index, result)
        return result
      })
    )
  )
}

interface CancellablePromise<Result> extends Promise<Result | undefined> {
  abort: (reason: string) => void
}

export function createCancellablePromise<Result>(
  cb: (signal: AbortSignal) => Promise<Result>
): CancellablePromise<Result> {
  const controller = new AbortController()

  const promise = Promise.resolve().then(() => {
    if (controller.signal.aborted) {
      return
    }

    // eslint-disable-next-line promise/no-callback-in-promise
    return cb(controller.signal)
  })

  return Object.assign(promise, {
    abort: (reason: string) => controller.abort(reason),
  })
}
