import { usePractice } from 'api/hooks/companies'
import type { Company } from 'api/types/companies'
import { useTrackUIView } from 'domains/analytics/hooks'
import type { PaymentStats } from 'domains/payment/types'
import { cdn } from 'kitchen/utils/helpers'
import { FormattedMessage } from 'react-intl'
import { Video, Surface, Button } from 'salad/components'
import { Box, HStack, VStack } from 'salad/primitives'
import { theme } from 'salad/stitches'
import type { ActiveSubscription } from '../../types'
import {
  checkIsCompanyEligibleForPaymentsFreeTrial,
  getPaymentsFreeTrialSubscriptionAddon,
} from '../../utils'

interface PaymentsFreeTrialVideoProps {
  company: Company
  paymentStats: PaymentStats
  onMore: (subscription: ActiveSubscription) => void
  onConfirm: (subscription: ActiveSubscription) => void
  confirming: boolean
}

export function PaymentsFreeTrialVideo({
  company: { id: companyId, subscription },
  paymentStats,
  onMore,
  onConfirm,
  confirming,
}: PaymentsFreeTrialVideoProps) {
  const practice = usePractice({ suspense: true }).data
  const isEligibleForPaymentsFreeTrial =
    checkIsCompanyEligibleForPaymentsFreeTrial(subscription, practice, paymentStats) &&
    !getPaymentsFreeTrialSubscriptionAddon(subscription)

  useTrackUIView({
    data: {
      target: 'payments-free-trial-flow',
      step: 'video',
      isEligibleForPaymentsFreeTrial,
    },
    companyId,
  })

  return (
    <Surface
      style={{
        maxWidth: 848,
        marginInline: 'auto',
        overflow: 'hidden',
        isolation: 'isolate',
        backgroundColor: theme.colors['surface-secondary-background'].toString(),
      }}
    >
      <VStack>
        <Video
          preload="auto"
          crossOrigin="anonymous"
          width={1280}
          height={720}
          autoPlay
          style={{ aspectRatio: '16 / 9' }}
        >
          <source src={cdn('/videos/pay-in-product-video-720p.webm')} type="video/webm" />
          <source src={cdn('/videos/pay-in-product-video-720p.mp4')} type="video/mp4" />
          <track
            label="English"
            kind="subtitles"
            srcLang="en"
            src={cdn('/videos/pay-in-product-subs.vtt')}
          />
        </Video>
        {isEligibleForPaymentsFreeTrial && (
          <Box
            p={16}
            style={{
              backgroundColor: theme.colors['surface-primary-background'].toString(),
              boxShadow: theme.shadows['elevation-top'].toString(),
            }}
          >
            <HStack
              gap={16}
              style={{
                maxWidth: 410,
                marginInline: 'auto',
                gridAutoColumns: 'auto 1fr',
                justifySelf: 'center',
              }}
            >
              <Button.Root
                variant="minor"
                size="medium"
                onClick={() => onMore(subscription)}
              >
                <Button.Content>
                  <FormattedMessage id="common.learn-more" defaultMessage="Learn more" />
                </Button.Content>
              </Button.Root>
              <Button.Root
                variant="main"
                size="medium"
                onClick={() => onConfirm(subscription)}
                loading={confirming}
              >
                <Button.Content>
                  <FormattedMessage
                    id="billing.payments-free-trial-flow.start-trial"
                    defaultMessage="Start free trial"
                  />
                </Button.Content>
              </Button.Root>
            </HStack>
          </Box>
        )}
      </VStack>
    </Surface>
  )
}
