import { useCurrentUser } from 'api/hooks/users'
import type { Company } from 'api/types/companies'
import { RouterLink } from 'kitchen/router'
import { SidebarNav } from 'salad/components'
import { SidebarLayout } from 'salad/layouts'
import { match } from 'ts-pattern'
import { Path } from '@app/constants'
import { UserActions } from '../user-actions'
import { AppSidebarCompanyNavItems } from './app-sidebar-company-nav-items'
import { AppSidebarGiftPaymentsFreeTrial } from './app-sidebar-gift-payments-free-trial'
import { AppSidebarHubNavItems } from './app-sidebar-hub-nav-items'
import { AppSidebarPaymentsFreeTrial } from './app-sidebar-payments-free-trial'

export type AppSidebarProps =
  | { layout: 'company-page'; company: Company }
  | { layout: 'hub-page' }

export function AppSidebar(props: AppSidebarProps) {
  const currentUser = useCurrentUser()

  return (
    <SidebarLayout.Root>
      <SidebarLayout.Logo as={RouterLink} to={Path.INDEX} />
      {currentUser.isSuccess && (
        <>
          <SidebarLayout.Tabs>
            <SidebarNav.Root>
              {match(props)
                .with({ layout: 'hub-page' }, () => (
                  <AppSidebarHubNavItems user={currentUser.data} />
                ))
                .with({ layout: 'company-page' }, ({ company }) => (
                  <AppSidebarCompanyNavItems company={company} />
                ))
                .exhaustive()}
            </SidebarNav.Root>
          </SidebarLayout.Tabs>
          <SidebarLayout.End>
            {match(props)
              .with({ layout: 'company-page' }, ({ company }) => (
                <AppSidebarPaymentsFreeTrial company={company} />
              ))
              .with({ layout: 'hub-page' }, () => <AppSidebarGiftPaymentsFreeTrial />)
              .exhaustive()}
            <UserActions layout="sidebar" user={currentUser.data} />
          </SidebarLayout.End>
        </>
      )}
    </SidebarLayout.Root>
  )
}
