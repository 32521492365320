import { useContext, createContext, useState } from 'react'
import { useIntl } from 'react-intl'
import * as Icons from '../../icons'
import { HStack, Text, VStack, Box } from '../../primitives'
import { theme } from '../../stitches'
import { Container } from '../container'
import { Label } from '../label'
import { NavigationButton } from '../navigation-button'

type DocumentGroupContextValue = [
  index: number,
  updater: (cb: (index: number) => number) => void
]

const DocumentGroupContext = createContext<DocumentGroupContextValue>([0, () => 0])

export interface DocumentGroupProps {
  defaultIndex?: number
  onNavigate?: (toIndex: number) => void
  children?: React.ReactNode
}

function DocumentGroup({ children, defaultIndex = 0, onNavigate }: DocumentGroupProps) {
  const [index, setIndex] = useState<number>(defaultIndex)

  const navigate = (callback: (previousIndex: number) => number) => {
    const next = callback(index)

    setIndex(next)
    onNavigate?.(next)
  }

  return (
    <DocumentGroupContext.Provider value={[index, navigate]}>
      <VStack
        css={{
          gridTemplate: '"header" auto',
          gridAutoRows: 'minmax(0px, 1fr)',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        {children}
      </VStack>
    </DocumentGroupContext.Provider>
  )
}

interface DocumentGroupHeaderProps {
  children?: React.ReactNode
}

function DocumentGroupHeader({ children }: DocumentGroupHeaderProps) {
  return (
    <Box
      css={{
        gridArea: 'header',
        backgroundColor: theme.colors['white'],
        paddingBlock: theme.space[12],
        height: '72px',
        borderBottom: `1px solid ${theme.colors['grey-10']}`,
      }}
    >
      <Container>
        <HStack
          css={{
            alignItems: 'center',
            gridTemplate: `
              "logo label     nav . ."
              "logo reference nav . ." auto / auto 1fr auto 0 0
            `,
            '@bp2': {
              gridTemplateColumns: 'auto 1fr auto 1fr auto',
            },
          }}
        >
          {children}
        </HStack>
      </Container>
    </Box>
  )
}

interface DocumentGroupLogoProps {
  children?: React.ReactNode
}

function DocumentGroupLogo({ children }: DocumentGroupLogoProps) {
  return (
    <Box
      css={{
        gridArea: 'logo',
        marginInlineEnd: theme.space[16],
        '@bp1': { marginInlineStart: -12, marginInlineEnd: theme.space[32] },
      }}
    >
      {children}
    </Box>
  )
}

interface DocumentGroupLabelProps {
  children?: React.ReactNode
}

function DocumentGroupLabel({ children }: DocumentGroupLabelProps) {
  return <Label css={{ gridArea: 'label' }}>{children}</Label>
}

interface DocumentGroupReferenceProps {
  children?: React.ReactNode
}

function DocumentGroupReference({ children }: DocumentGroupReferenceProps) {
  return (
    <Text
      variant="paragraph-16"
      css={{ gridArea: 'reference', paddingBlock: theme.space[2], whiteSpace: 'nowrap' }}
    >
      {children}
    </Text>
  )
}

interface DocumentGroupNavigationProps {
  length: number
  showSingle?: boolean
}

function DocumentGroupNavigation({
  length,
  showSingle = false,
}: DocumentGroupNavigationProps) {
  const intl = useIntl()
  const [index, setIndex] = useContext(DocumentGroupContext)

  if (length <= 1 && showSingle === false) {
    return null
  }

  return (
    <HStack
      data-document-group-navigation
      as="nav"
      gap={16}
      css={{
        gridArea: 'nav',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBlock: theme.space[10],
        paddingInlineStart: theme.space[16],
        '@bp2': { paddingInline: theme.space[16] },
      }}
    >
      <NavigationButton
        size="small"
        aria-label={intl.formatMessage({
          id: 'salad.document-group.previous-document',
          defaultMessage: 'Previous document',
        })}
        disabled={index === 0}
        onClick={() => setIndex((index) => Math.max(index - 1, 0))}
      >
        <Icons.S16.Chevron variant="left" />
      </NavigationButton>
      <Text variant="title-16">
        {index + 1} / {length}
      </Text>
      <NavigationButton
        size="small"
        aria-label={intl.formatMessage({
          id: 'salad.document-group.next-document',
          defaultMessage: 'Next document',
        })}
        disabled={index === length - 1}
        onClick={() => setIndex((index) => Math.min(index + 1, length - 1))}
      >
        <Icons.S16.Chevron variant="right" />
      </NavigationButton>
    </HStack>
  )
}

interface DocumentGroupContentProps<Value> {
  values: Value[]
  children: (context: { value: Value }) => React.ReactNode
}

function DocumentGroupContent<Value>({
  values,
  children,
}: DocumentGroupContentProps<Value>) {
  const [index] = useContext(DocumentGroupContext)

  return <>{children({ value: values[index] })}</>
}

export {
  DocumentGroup as Root,
  DocumentGroupHeader as Header,
  DocumentGroupLogo as Logo,
  DocumentGroupLabel as Label,
  DocumentGroupReference as Reference,
  DocumentGroupNavigation as Navigation,
  DocumentGroupContent as Content,
}
