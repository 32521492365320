import type { Company } from 'api/types/companies'
import { useSendInteraction } from 'domains/analytics/hooks'
import { ImpossibleError } from 'kitchen/utils/error'
import { assert } from 'kitchen/utils/helpers'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Toast } from 'salad/components'
import type { PaymentStats } from '../../../payment/types'
import { useStartPaymentsFreeTrial } from '../../queries'
import { PaymentsFreeTrialConfirmation } from './payments-free-trial-confirmation'
import { PaymentsFreeTrialOverview } from './payments-free-trial-overview'
import { PaymentsFreeTrialVideo } from './payments-free-trial-video'

type PaymentsFreeTrialFlowStep = 'video' | 'overview' | 'confirmation'

interface PaymentsFreeTrialFlowProps {
  initialStep: PaymentsFreeTrialFlowStep
  company: Company
  paymentStats: PaymentStats
  onExit: () => void
}

export function PaymentsFreeTrialFlow({
  company,
  paymentStats,
  initialStep,
  onExit,
}: PaymentsFreeTrialFlowProps) {
  const sendInteraction = useSendInteraction(company.id)

  const [step, setStep] = useState<PaymentsFreeTrialFlowStep>(initialStep)

  const toast = Toast.useContext()
  const startPaymentsFreeTrial = useStartPaymentsFreeTrial({
    onError: () =>
      toast.show(
        <Toast.Root variant="error">
          <Toast.Title>
            <FormattedMessage
              id="billing.payments-free-trial-flow.errors.start-trial"
              defaultMessage="Failed to start trial"
            />
          </Toast.Title>
        </Toast.Root>
      ),
  })

  switch (step) {
    case 'video':
      return (
        <PaymentsFreeTrialVideo
          company={company}
          paymentStats={paymentStats}
          onMore={() => {
            setStep('overview')

            sendInteraction({
              type: 'click',
              target: 'learn-more-payments-free-trial',
            })
          }}
          onConfirm={async (subscription) => {
            await startPaymentsFreeTrial.mutateAsync({
              subscriptionId: subscription.id,
            })

            setStep('confirmation')

            sendInteraction({
              type: 'click',
              target: 'start-payments-free-trial',
            })
          }}
          confirming={startPaymentsFreeTrial.isLoading}
        />
      )
    case 'overview':
      return (
        <PaymentsFreeTrialOverview
          company={company}
          onConfirm={async () => {
            assert(company.subscription)

            await startPaymentsFreeTrial.mutateAsync({
              subscriptionId: company.subscription.id,
            })

            setStep('confirmation')
            sendInteraction({
              type: 'click',
              target: 'start-payments-free-trial',
            })
          }}
          confirming={startPaymentsFreeTrial.isLoading}
        />
      )
    case 'confirmation':
      assert(company.subscription)
      return (
        <PaymentsFreeTrialConfirmation
          subscription={company.subscription}
          onDone={() => onExit()}
        />
      )
    default:
      throw new ImpossibleError('Unhandled payments free trial flow step', step)
  }
}
