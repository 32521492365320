import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { CacheKey } from 'kitchen/constants'
import { useFetch } from 'kitchen/context/fetch'
import type {
  UseMutationOptions,
  UseQueryOptions,
  QueryHookFactory,
  MutationHookFactory,
} from 'kitchen/types'
import { immutableRequestOptions } from 'kitchen/utils/fetch'
import { assert } from 'kitchen/utils/helpers'
import { getRequiredUserSettings } from 'kitchen/utils/users'
import { useCallback } from 'react'
import { getUserSettings, updateUserSettings } from '../requests/user-settings'
import type { Company } from '../types/companies'
import type {
  UserSettings,
  UserPerCompanySettings,
  UpdateUserSettingsPayload,
} from '../types/user-settings'

export interface UserSettingsOptions<Result = UserSettings>
  extends UseQueryOptions<UserSettings, Result> {}

export const useUserSettings: QueryHookFactory<void, UserSettings> = (options) => {
  const fetch = useFetch()
  return useQuery({
    queryKey: [CacheKey.USER_SETTINGS],
    queryFn: ({ signal }) => getUserSettings(fetch, signal),
    ...immutableRequestOptions,
    ...options,
  })
}

export interface UpdateUserSettingsOptions
  extends UseMutationOptions<Partial<UserSettings>, void> {}

export const useUpdateUserSettings: MutationHookFactory<
  UpdateUserSettingsPayload,
  void
> = (options) => {
  const queryClient = useQueryClient()
  const fetch = useFetch()
  return useMutation((payload) => updateUserSettings(fetch, payload), {
    ...options,
    onMutate: (next) => {
      queryClient.setQueriesData<UserSettings>([CacheKey.USER_SETTINGS], (prev) =>
        getRequiredUserSettings({ ...prev, ...next })
      )

      return options?.onMutate?.(next)
    },
    onSuccess: async (data, variables, context) => {
      await options?.onSuccess?.(data, variables, context)
      await queryClient.invalidateQueries([CacheKey.USER_SETTINGS], {
        refetchType: 'all',
      })

      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export function useUserPerCompanySettings(company: Company) {
  const selectPerCompanySettings = useCallback(
    (data: UserSettings): UserPerCompanySettings => ({
      paymentVisibilityBanner: 'VISIBLE',
      invoicesSorting: [{ id: 'dueDate', desc: true }],
      ...data.perCompany[company.id],
    }),
    [company]
  )

  const result = useUserSettings({
    select: selectPerCompanySettings,
    suspense: true,
  })

  assert(result.data)
  return result.data
}

export function useUpdateUserPerCompanySettings(company: Company) {
  const userSettings = useUserSettings({ suspense: true }).data
  const userPerCompanySettings = useUserPerCompanySettings(company)
  const updateUserSettings = useUpdateUserSettings()

  assert(userSettings)

  return useMutation((payload: Partial<UserPerCompanySettings>) =>
    updateUserSettings.mutateAsync({
      perCompany: {
        ...userSettings.perCompany,
        [company.id]: { ...userPerCompanySettings, ...payload },
      },
    })
  )
}
